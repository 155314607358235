export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      contact_organization
      contact_person
      contact_email
      contact_phone
      contact_link
      feedback_link
      provides_households
      benefits
      notificationMessage
      isInRevision_at
      projectClosed_at
      workflowSet_at
      a_preplanning_closed
      b_initial_consultation_closed
      c_planning_closed
      d_final_consultation_closed
      e_approval_closed
      nested_update_at
      workflowSet
      summaryVerified
      isInRevision
      projectClosed
      summaryEditable
      admins
      subscribers
      adminGroups
      subscriberGroups
      name
      description
      longitude
      latitude
      additionalInfo
      projectType
      size
      capacity
      turbines
      height
      diameter
      startDate
      endDate
      rotorDiameter
      municipalities
      preAgreementLandowners
      relationNeighbours
      stage
      deadlines {
        items {
          id
          date
          owner
          deadlineEnum
          stage
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          personaId
          persona {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documents(limit: 5000) {
        items {
          id
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          name
          description
          deadline
          owners
          ownerGroups
          publishable
          subscribers
          subscriberGroups
          mandatory
          stage
          status
          comments(limit: 5000) {
            items {
              owner
              subscribers
              subscriberGroups
              section
              page
              version
              comment
              personaId
              documentId
              document {
                name
              }
              persona {
                id
                owner
                given_name
                family_name
                email
                phone_number
                role
                domain
                dateOfPrivacyConsent
                municipality
                createdAt
                updatedAt
                organisationPersonnelId
              }
              id
              createdAt
              updatedAt
            }
            nextToken
          }
          tasks(limit: 5000) {
            items {
              id
              documentId
              taskId
              task {
                id
                done_at
                deadline
                title
                mandatory
                description
                owners
                ownerGroups
                subscribers
                subscriberGroups
                stage
                done
                projectId
                assigneeId
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owners
              subscribers
            }
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks(limit: 5000) {
        items {
          id
          done_at
          deadline
          title
          description
          owners
          mandatory
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          documents {
            items {
              id
              documentId
              taskId
              document {
                id
                name
                description
                deadline
                owners
                ownerGroups
                subscribers
                subscriberGroups
                mandatory
                stage
                status
                assigneeId
                reviewerId
                projectId
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owners
              subscribers
            }
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentId
      mainAgent {
        id
        owner
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        organisation {
          id
          domain
          role
          municipality
          createdAt
          updatedAt
        }
      }
      mainDeveloperId
      mainDeveloper {
        id
        owner
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        organisation {
          id
          domain
          role
          municipality
          createdAt
          updatedAt
        }
      }
      smes {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developers {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      agents {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        provides_households
        benefits
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        additionalInfo
        projectType
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        rotorDiameter
        municipalities
        preAgreementLandowners
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            persona {
              id
              owner
              given_name
              family_name
              email
              phone_number
              role
              domain
              dateOfPrivacyConsent
              municipality
            }
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            name
            publishable
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            comments(limit: 5000) {
              items {
                owner
                subscribers
                subscriberGroups
                section
                page
                version
                comment
                personaId
                documentId
                document {
                  name
                }
                persona {
                  id
                  owner
                  given_name
                  family_name
                  email
                  phone_number
                  role
                  domain
                  dateOfPrivacyConsent
                  municipality
                  createdAt
                  updatedAt
                  organisationPersonnelId
                }
                id
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            assigneeId
            assignedTo {
              id
              owner
              given_name
              family_name
              email
              phone_number
              role
              domain
              dateOfPrivacyConsent
              municipality
              createdAt
              updatedAt
              organisationPersonnelId
            }
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            done_at
            deadline
            title
            description
            owners
            ownerGroups
            mandatory
            subscribers
            subscriberGroups
            stage
            done
            projectId
            assigneeId
            assignedTo {
              id
              owner
              given_name
              family_name
              email
              phone_number
              role
              domain
              dateOfPrivacyConsent
              municipality
              createdAt
              updatedAt
              organisationPersonnelId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
