import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  Container,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { Authenticator } from "@aws-amplify/ui-react";
import { blueLink } from "../../shared";
import NavigationBar from "../NavigationBar";

export default function DeveloperFirstTime() {
  const date = new Date();
  const { t } = useTranslation();

  return (
    <Authenticator>
      {({ user }) => (
        <>
          <NavigationBar />
          <AppLayout
            navigationHide={true}
            toolsHide={true}
            content={
              <ContentLayout
                header={
                  <SpaceBetween size="l">
                    <p style={{ color: blueLink, fontSize: "14px" }}>
                      {t("Dashboard")}
                    </p>
                    <Header
                      variant="h1"
                      description={`${t(date.toDateString().slice(0, 3))} ${t(
                        date.toDateString().slice(4, 7)
                      )} ${t(date.toDateString().slice(7))}`}
                    >
                      {t("Welcome to EasyPermits")},{" "}
                      {user.attributes.given_name}!
                    </Header>
                    <div />
                  </SpaceBetween>
                }
              >
                <Container>
                  <div
                    style={{
                      textAlign: "center",
                      paddingLeft: 120,
                      paddingRight: 120,
                      paddingTop: 50,
                      paddingBottom: 50,
                    }}
                  >
                    <h3>{t("It seems like it's your first time here.")}</h3>
                    <p>
                      {t(
                        "Our app is designed to streamline and simplify the permitting process for wind farm developers like you. As you begin, please proceed by submitting the Project Setup Form, where you can provide essential information about your project application."
                      )}
                    </p>
                    <p>
                      {t(
                        "This form will help you communicate all the basic, important details required by the permitting agent. Our goal is to ensure a seamless and efficient process, facilitating clear communication and collaboration between you and the permitting authorities."
                      )}
                    </p>
                    <p>
                      {t(
                        "Thank you for choosing EasyPermits. Let's work together to create a greener and sustainable future!"
                      )}
                    </p>
                    <div style={{ paddingTop: 40 }}>
                      <Button
                        variant="primary"
                        href="/project-setup"
                        iconAlign="left"
                        iconName="add-plus"
                      >
                        {t("Submit New Project")}
                      </Button>
                    </div>
                  </div>
                </Container>
              </ContentLayout>
            }
          />
        </>
      )}
    </Authenticator>
  );
}
