import { useEffect, useState, useContext } from "react";
import { UserContext } from "../UserContext";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  BreadcrumbGroup,
  Container,
  FormField,
  Select,
  Input,
  ColumnLayout,
  Textarea,
  Box,
  Modal,
  RadioGroup,
  Multiselect,
} from "@cloudscape-design/components";
import { Authenticator } from "@aws-amplify/ui-react";
import { Divider, darkGray, denmarkMunicipalityList } from "../shared";
import NavigationBar from "./NavigationBar";
import AccessDenied from "../AccessDenied";
import DataPrivacyPopup from "../DataPrivacyPopup";

export default function ProjectSetup() {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const [isPageLoading, setPageLoading] = useState(true);
  // Form fields
  const [modal, showModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [size, setSize] = useState(null);
  const [households, setHouseholds] = useState(0);
  const [capacity, setCapacity] = useState(null);
  const [turbinesNum, setTurbinesNum] = useState(null);
  const [height, setHeight] = useState(null);
  const [diameter, setDiameter] = useState(null);
  const [preAgreement, setPreAgreement] = useState(true);
  const [relationNeighbours, setRelationNeighbours] = useState("");
  const [benefits, setBenefits] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [type, setType] = useState({
    value: "wind",
    label: "Wind Farm",
  });
  const [municipality, setMunicipality] = useState([]);
  const [organisation, setOrganisation] = useState("");
  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [projectInitializing, setProjectInitializing] = useState(false);

  const denmark_coordinates = {
    min_lat: 54.85,
    max_lat: 57.84,
    min_lon: 8,
    max_lon: 12.6,
  };

  // Create new project using AppSync
  const submitProject = async () => {
    let projectId = "";
    setProjectInitializing(true);
    const formInputs = {
      name,
      description,
      projectType: type.value,
      latitude,
      longitude,
      size,
      capacity,
      provides_households: households,
      turbines: turbinesNum,
      height,
      diameter,
      benefits,
      municipalities: municipality.map((item) => item.value),
      adminGroups: municipality.map((item) => item.value),
      preAgreementLandowners: preAgreement,
      relationNeighbours,
      additionalInfo,
      mainDeveloperId: userDetails.id,
      contact_organization: organisation,
      stage: "a_preplanning",
    };

    await API.graphql(
      graphqlOperation(mutations.createProject, {
        input: formInputs,
      })
    )
      .then(async (res) => {
        console.log("ProjectSetup: Successfully created new project!");
        projectId = res.data.createProject.id;
        await createDocumentsAndTasks(projectId);
        navigate("/project/" + projectId);
      })
      .catch((error) => console.error(error));
  };

  const createDocumentsAndTasks = async (projectId) => {
    const documentIds = {};
    for (const documentTemplate of documentTemplates.toReversed()) {
      await createDocument(projectId, documentTemplate, documentIds);
    }
    const documentTasks = [];
    for (const taskTemplate of taskTemplates.toReversed()) {
      await createTask(projectId, taskTemplate, documentIds, documentTasks);
    }
    for (const documentTask of documentTasks) {
      await assignDocumentTask(documentTask);
    }
  };

  const assignDocumentTask = async ({ documentId, taskId }) => {
    await API.graphql(
      graphqlOperation(mutations.createDocumentTasks, {
        input: { documentId, taskId },
      })
    )
      .then((res) => {
        console.log("Successfully created new task document assignment!");
      })
      .catch((error) => console.error(error));
  };

  const createTask = async (
    projectId,
    taskTemplate,
    documentIds,
    documentTasks
  ) => {
    const task = {
      projectId,
      title: taskTemplate.taskTitle,
      description: taskTemplate.taskDescription,
      stage: taskTemplate.stage,
      mandatory: taskTemplate?.mandatory || false,
      done: false,
      ownerGroups: municipality.map((m) => m.value),
    };
    if (taskTemplate.assignedTo === "developers") {
      task["assigneeId"] = userDetails.id;
    }
    await API.graphql(
      graphqlOperation(mutations.createTask, {
        input: task,
      })
    )
      .then((res) => {
        console.log("Successfully created new task!");
        if (taskTemplate.relevantDocuments) {
          for (const relevantDocument of taskTemplate.relevantDocuments) {
            if (documentIds[relevantDocument]) {
              documentTasks.push({
                documentId: documentIds[relevantDocument],
                taskId: res.data.createTask.id,
              });
            }
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const createDocument = async (projectId, documentTemplate, documentIds) => {
    const document = {
      projectId,
      name: documentTemplate.documentName,
      description: documentTemplate.documentDescription,
      mandatory: documentTemplate.documentMandatory,
      stage: documentTemplate.stage,
      publishable: documentTemplate?.publishable || false,
      status: "a_upload",
      ownerGroups: municipality.map((m) => m.value),
    };
    if (documentTemplate.assignedTo === "developers") {
      document["assigneeId"] = userDetails.id;
    }
    await API.graphql(
      graphqlOperation(mutations.createDocument, {
        input: document,
      })
    )
      .then((res) => {
        console.log("Successfully created new document!");
        documentIds[documentTemplate.documentName] = res.data.createDocument.id;
      })
      .catch((error) => console.error(error));
  };

  const getWorkflowTemplates = async () => {
    await API.graphql(
      graphqlOperation(queries.listDocumentTemplates, {
        filter: {
          stage: {
            eq: "a_preplanning",
          },
        },
      })
    )
      .then((res) => {
        setDocumentTemplates(
          res?.data?.listDocumentTemplates?.items.sort((a, b) => {
            const nameA = a.createdAt; // ignore upper and lowercase
            const nameB = b.createdAt; // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
          })
        );
      })
      .catch((error) => console.error(error.message));
    await API.graphql(
      graphqlOperation(queries.listTaskTemplates, {
        filter: {
          stage: {
            eq: "a_preplanning",
          },
        },
      })
    )
      .then((res) => {
        setTaskTemplates(res?.data?.listTaskTemplates?.items);
        setPageLoading(false);
      })
      .catch((error) => console.error(error.message));
  };

  const validateValue = (value, name) => {
    if (value) {
      if (name === "latitude") {
        if (
          value <
          (municipality?.length > 0
            ? Math.min(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.min_lat
                )
              )
            : denmark_coordinates.min_lat)
        )
          return "Value is too small";
        if (
          value >
          (municipality?.length > 0
            ? Math.max(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.max_lat
                )
              )
            : denmark_coordinates.max_lat)
        )
          return "Value is too large";
      } else {
        if (
          value <
          (municipality?.length > 0
            ? Math.min(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.min_lon
                )
              )
            : denmark_coordinates.min_lon)
        )
          return "Value is too small";
        if (
          value >
          (municipality?.length > 0
            ? Math.max(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.max_lon
                )
              )
            : denmark_coordinates.max_lon)
        )
          return "Value is too large";
      }
    } else return;
  };

  const validateBenefitsInput = (string) => {
    // check if message contains a url
    if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(string)
    ) {
      // ensure URL starts with HTTP/HTTPS (https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url)
      const urlRegExp =
        /https?:\/\/?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      if (string) {
        if (!string.match(urlRegExp)) return "Link must start with http(s)://";
      } else return;
    }
  };

  useEffect(() => {
    getWorkflowTemplates();
  }, [navigate]);

  useEffect(() => {
    if (userDetails?.organisation?.name)
      setOrganisation(userDetails?.organisation?.name);
  }, [userDetails?.organisation?.name]);

  return (
    <Authenticator>
      {
        // Show Loading text when page mounts instead of AccessDenied screen when user access ProjectSetup
        isPageLoading ? (
          <p style={{ textAlign: "center" }}>{t("Loading")}...</p>
        ) : // Only Developers can see this page
        userDetails && userDetails.role !== "developers" ? (
          <AccessDenied />
        ) : (
          <>
            {/* Data Privacy disclaimer popup  */}
            {userDetails.dateOfPrivacyConsent === null && <DataPrivacyPopup />}

            <NavigationBar />
            <AppLayout
              navigationHide={true}
              toolsHide={true}
              content={
                <ContentLayout
                  header={
                    <SpaceBetween size="l">
                      <div />
                      <BreadcrumbGroup
                        items={[
                          { text: t("Dashboard"), href: "/dashboard" },
                          { text: t("Project Setup Request") },
                        ]}
                        ariaLabel="Breadcrumbs"
                      />
                      <Header
                        variant="h1"
                        description={t(
                          "Fill out the below form and submit the form to send it to the Permitting Agent"
                        )}
                      >
                        {t("Project Setup Request")}
                      </Header>
                      <div />
                    </SpaceBetween>
                  }
                >
                  <Container
                    header={
                      <Header variant="h2">{t("Project Summary")}</Header>
                    }
                  >
                    <div style={{ color: darkGray }}>
                      <p>
                        {t(
                          "Please provide essential information required for the permitting process. By completing this form, you'll offer a comprehensive overview of your proposed project. We'll gather vital details such as project location, project type and community engagement plans. This initial step will pave the way for a smooth and efficient permitting process."
                        )}
                      </p>
                    </div>
                    <br />

                    <SpaceBetween direction="vertical" size="xl">
                      <ColumnLayout columns={3}>
                        <div>
                          <FormField
                            stretch
                            label={t("Organisation")}
                            description={t("Name of the organisation")}
                          >
                            <Input
                              placeholder="Organisation"
                              value={organisation}
                              onChange={(event) =>
                                setOrganisation(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            stretch
                            label={t("Project name")}
                            description={t("Name of the project")}
                          >
                            <Input
                              type="text"
                              placeholder={t("Project name")}
                              value={name}
                              onChange={(event) => setName(event.detail.value)}
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            stretch
                            label={t("Project Type")}
                            description={t("Specify the type of the project")}
                          >
                            <Select
                              placeholder={t("Please select")}
                              selectedAriaLabel={t("Project type selected")}
                              selectedOption={type}
                              disabled={true}
                              onChange={({ detail }) =>
                                setType(detail.selectedOption)
                              }
                              options={[
                                {
                                  value: "wind",
                                  label: "Wind Farm",
                                },
                                {
                                  value: "solar",
                                  label: "Solar",
                                },
                              ]}
                            />
                          </FormField>
                        </div>
                      </ColumnLayout>

                      <FormField
                        stretch
                        label={t("Project Overview")}
                        description={t(
                          "Detailed description of the proposed project, basic information, company description, etc."
                        )}
                      >
                        <Textarea
                          rows={2}
                          ariaLabel="project-overview"
                          placeholder={t("Enter description here...")}
                          onChange={({ detail }) =>
                            setDescription(detail.value)
                          }
                          value={description}
                        />
                      </FormField>
                      <ColumnLayout columns={3}>
                        <div>
                          <FormField
                            label={t("Municipality")}
                            description={t("Municipality of the project")}
                          >
                            <Multiselect
                              placeholder={t("Select municipalities")}
                              selectedAriaLabel="Municipality selected"
                              selectedOptions={municipality}
                              onChange={({ detail }) => {
                                setMunicipality(detail.selectedOptions);
                              }}
                              options={denmarkMunicipalityList}
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={t("Location - Latitude")}
                            description={t(
                              "Latitude location of the project on the map"
                            )}
                            constraintText={`${t("Value must be between")} ${
                              municipality?.length > 0
                                ? Math.min(
                                    ...municipality?.map(
                                      (mu) =>
                                        denmarkMunicipalityList.find(
                                          (m) => m.value === mu.value
                                        )?.min_lat
                                    )
                                  )
                                : denmark_coordinates.min_lat
                            } ${t("and")} ${
                              municipality?.length > 0
                                ? Math.max(
                                    ...municipality?.map(
                                      (mu) =>
                                        denmarkMunicipalityList.find(
                                          (m) => m.value === mu.value
                                        )?.max_lat
                                    )
                                  )
                                : denmark_coordinates.max_lat
                            }`}
                            errorText={validateValue(latitude, "latitude")}
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={latitude}
                              onChange={(event) =>
                                setLatitude(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={t("Location - Longitude")}
                            description={t(
                              "Longitude location of the project on the map"
                            )}
                            constraintText={`${t("Value must be between")} ${
                              municipality?.length > 0
                                ? Math.min(
                                    ...municipality?.map(
                                      (mu) =>
                                        denmarkMunicipalityList.find(
                                          (m) => m.value === mu.value
                                        )?.min_lon
                                    )
                                  )
                                : denmark_coordinates.min_lon
                            } ${t("and")} ${
                              municipality?.length > 0
                                ? Math.max(
                                    ...municipality?.map(
                                      (mu) =>
                                        denmarkMunicipalityList.find(
                                          (m) => m.value === mu.value
                                        )?.max_lon
                                    )
                                  )
                                : denmark_coordinates.max_lon
                            }`}
                            errorText={validateValue(longitude, "longitude")}
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={longitude}
                              onChange={(event) =>
                                setLongitude(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                      </ColumnLayout>

                      <ColumnLayout columns={3}>
                        <div>
                          <FormField
                            label={t("Number of Turbines")}
                            description={t("Total number of turbines")}
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={turbinesNum}
                              onChange={(event) =>
                                setTurbinesNum(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={t("Expected Tip Height")}
                            description={t(
                              "Height of the wind turbines in meters"
                            )}
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={height}
                              onChange={(event) =>
                                setHeight(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={
                              <span>
                                {t("Expected rotor diameter")}
                                <i>({t("optional")})</i>
                              </span>
                            }
                            description={t(
                              "Rotor diameter of the wind turbines in meters"
                            )}
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={diameter}
                              onChange={(event) =>
                                setDiameter(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                      </ColumnLayout>

                      <ColumnLayout columns={3}>
                        <div>
                          <FormField
                            label={<span>{t("Overall capacity")}</span>}
                            description="MW"
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={capacity}
                              onChange={(event) =>
                                setCapacity(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={
                              <span>
                                {t("Households")} <i>({t("optional")})</i>
                              </span>
                            }
                            description={t(
                              "Average number of households to supply energy to"
                            )}
                          >
                            <Input
                              placeholder="0"
                              type="number"
                              value={households}
                              onChange={(event) =>
                                setHouseholds(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={
                              <span>
                                {t("Size of project")} <i>({t("optional")})</i>
                              </span>
                            }
                            description={t("Size of project in hectares")}
                          >
                            <Input
                              type="number"
                              placeholder="0"
                              value={size}
                              onChange={(event) => setSize(event.detail.value)}
                            />
                          </FormField>
                        </div>
                      </ColumnLayout>

                      <Divider />
                      <ColumnLayout columns={3}>
                        <div>
                          <FormField
                            label={t("Is there a pre-agreement with owners?")}
                            description={t("Do you have a written document?")}
                          >
                            <RadioGroup
                              onChange={({ detail }) =>
                                setPreAgreement(detail.value)
                              }
                              value={preAgreement}
                              items={[
                                { value: true, label: t("Yes") },
                                { value: false, label: t("No") },
                              ]}
                            />
                          </FormField>
                        </div>
                        <div>
                          <FormField
                            label={
                              <span>
                                {t("Relation to neighbors")}{" "}
                                <i>({t("optional")})</i>
                              </span>
                            }
                            description={t(
                              "Information about community engagement and the current relationship to neighbours"
                            )}
                          >
                            <Input
                              type="text"
                              placeholder={t("Enter information here...")}
                              value={relationNeighbours}
                              onChange={(event) =>
                                setRelationNeighbours(event.detail.value)
                              }
                            />
                          </FormField>
                        </div>
                      </ColumnLayout>
                      <FormField
                        stretch
                        label={
                          <span>
                            {t("Benefits for the Community")}{" "}
                            <i>({t("optional")})</i>
                          </span>
                        }
                        description={t(
                          "Provide information regarding community benefits. You can also add a link to your project website. Link must start with http(s)://"
                        )}
                        constraintText={t(
                          "Recommendation to also add the community testimonial video on your project website."
                        )}
                        errorText={validateBenefitsInput(benefits)}
                      >
                        <Textarea
                          rows={2}
                          ariaLabel="project-benefits"
                          placeholder={t("Enter information here...")}
                          onChange={({ detail }) => setBenefits(detail.value)}
                          value={benefits}
                        />
                      </FormField>
                      <FormField
                        stretch
                        label={
                          <span>
                            {t("Additional information")}{" "}
                            <i>({t("optional")})</i>
                          </span>
                        }
                        description={t(
                          "Please provide any additional technical information relevant to the project eg. design, configuration, known issues, nature, etc."
                        )}
                      >
                        <Textarea
                          rows={2}
                          ariaLabel="project-overview"
                          placeholder={t("Enter information here...")}
                          onChange={({ detail }) =>
                            setAdditionalInfo(detail.value)
                          }
                          value={additionalInfo}
                        />
                      </FormField>
                      <b style={{ color: darkGray }}>
                        {t(
                          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form to ensure a seamless submission process."
                        )}
                      </b>
                    </SpaceBetween>
                  </Container>
                  <div style={{ marginTop: 30, float: "right" }}>
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="link" href="/dashboard">
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={
                          // Only enable Submit button if all required fields are provided
                          !(
                            name &&
                            description &&
                            latitude &&
                            longitude &&
                            municipality &&
                            type &&
                            turbinesNum &&
                            height &&
                            capacity
                          ) || projectInitializing
                        }
                        onClick={() => {
                          showModal(true);
                        }}
                      >
                        {projectInitializing
                          ? t("Please wait") + "..."
                          : t("Submit New Project")}
                      </Button>
                    </SpaceBetween>
                  </div>

                  {/* Confirm form submission popup */}
                  <Modal
                    onDismiss={() => showModal(false)}
                    visible={modal}
                    closeAriaLabel="Close modal"
                    header={t("Are you sure you want to submit?")}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button
                            variant="link"
                            onClick={() => showModal(false)}
                          >
                            {t("Cancel")}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              submitProject();
                              showModal(false);
                            }}
                          >
                            {t("Confirm Submission")}
                          </Button>
                        </SpaceBetween>
                      </Box>
                    }
                  >
                    <b>
                      {t(
                        "A notification will be sent to a Permitting Agent with your project submission request. You can get in touch with the Permitting Agent once an Agent is assigned on the project."
                      )}
                    </b>
                    <p>
                      {t(
                        "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form."
                      )}
                    </p>
                  </Modal>
                </ContentLayout>
              }
            />
          </>
        )
      }
    </Authenticator>
  );
}
