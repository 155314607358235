import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProjectDetailsContext } from "./ProjectDashboard";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
  warningOrange,
  isDeadlinePassed,
  calculatePriority,
} from "../../shared";
import {
  Container,
  Header,
  Table,
  Box,
  Link,
} from "@cloudscape-design/components";

// COLLAPSED TASKS VIEW
// Only show Tasks of the current project stage
// For all tasks in all stages, go to ExpandedTasks.js

export default function ProjectTasks(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { projectDetails } = useContext(ProjectDetailsContext);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // get all project tasks
    if (projectDetails?.tasks?.items)
      setTasks(
        projectDetails?.tasks?.items
          .filter((task) => task.stage === props.viewingStage && !task.done)
          .slice(0, 10) // only get first 10 tasks to prevent displaying too much
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails?.documents, projectDetails?.tasks, props.viewingStage]);

  return (
    <Container
      fitHeight
      header={<Header variant="h2">{t("Tasks")}</Header>}
      footer={
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Link href={"/project/" + id + "/tasks"}>{t("View all tasks")}</Link>
        </div>
      }
    >
      <Table
        variant="embedded"
        sortingDisabled
        resizableColumns
        columnDefinitions={[
          {
            id: "priority",
            header: t("Priority"),
            cell: (e) => calculatePriority(e.deadline, false) || "-",
            sortingField: "priority",
            width: 90,
          },
          {
            id: "taskName",
            header: t("Task Name"),
            cell: (e) => t(e.title) || "-",
            sortingField: "taskName",
            minWidth: 250,
          },
          {
            id: "dueDate",
            header: t("Due Date"),
            cell: (e) =>
              isDeadlinePassed(e.deadline) ? (
                <b style={{ color: warningOrange }}>{e.deadline}</b>
              ) : (
                e.deadline || "-"
              ),
            sortingField: "dueDate",
            width: 165,
          },
          {
            id: "assignedTo",
            header: t("Assigned to"),
            cell: (e) =>
              (e?.assignedTo &&
                `${e?.assignedTo?.given_name} ${e?.assignedTo?.family_name}`) ||
              "-",
            sortingField: "assignedTo",
          },
        ]}
        items={tasks.sort((a, b) => {
          if (a.deadline && b.deadline) {
            return a.deadline - b.deadline;
          } else {
            return a.deadline ? -1 : 1;
          }
        })}
        loadingText="Loading resources"
        trackBy="taskName"
        visibleColumns={[
          "priority",
          "taskName",
          "linkedTo",
          "dueDate",
          "assignedTo",
        ]}
        empty={
          <>
            <b>{t("All done!")}</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              {t("No open tasks to display")}
            </Box>
          </>
        }
      />
    </Container>
  );
}
