import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as optimizedQueries from "../../graphql/optimizedQueries";
import { UserContext } from "../../UserContext";
import {
  darkGray,
  currentDate,
  lightGray,
  primaryBlue,
  MapCodeToLabel,
} from "../../shared";
import {
  AppLayout,
  ContentLayout,
  ColumnLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Container,
  Modal,
  Multiselect,
  Button,
  StatusIndicator,
  Grid,
  Link,
  Select,
  DatePicker,
  FormField,
  Input,
  RadioGroup,
  Table,
  Box,
  Tabs,
  Popover,
} from "@cloudscape-design/components";
import NavigationBar from "../NavigationBar";
import AccessDenied from "../../AccessDenied";
import DataPrivacyPopup from "../../DataPrivacyPopup";
import PredefinedTemplateImage from "../../assets/predefined-template.png";
import OwnTemplateImage from "../../assets/own-template.png";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function WorkflowSetup() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { userDetails } = useContext(UserContext);
  const tasks = [];
  const [screen, setScreen] = useState(1);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [municipality, setMunicipality] = useState("_820");
  const [workflowPending, setWorkflowPending] = useState(false);
  const [templateType, setTemplateType] = useState(false);
  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [taskTemplates, setTaskTemplates] = useState({
    b_initial_consultation: [],
    c_planning: [],
    d_final_consultation: [],
    e_approval: [],
  });

  const [selectedDocuments, setSelectedDocuments] = useState(documentTemplates);
  const [selectedTasks, setSelectedTasks] = useState({
    b_initial_consultation: [],
    c_planning: [],
    d_final_consultation: [],
    e_approval: [],
  });

  const stages = [
    "a_preplanning",
    "b_initial_consultation",
    "c_planning",
    "d_final_consultation",
    "e_approval",
  ];

  const getProjectDetails = async () => {
    const res = await API.graphql(
      graphqlOperation(optimizedQueries.getProject, {
        id: id,
      })
    );
    if (res.data.getProject) {
      setProjectDetails(res.data.getProject);
    }
  };

  // ------------------------ MILESTONES DEADLINES ------------------------
  const [loadingCircle, showLoadingCircle] = useState(false);

  // pre-planning
  var [pp_Documents_Deadline, set_pp_Documents_Deadline] = useState("");
  var [pp_CityCouncil_Deadline, set_pp_CityCouncil_Deadline] = useState("");

  // Initial Public Consultation
  var [ipc_Documents_Deadline, set_ipc_Documents_Deadline] = useState("");
  var [ipc_Consultation_Deadline, set_ipc_consultation_Deadline] = useState("");
  var [ipc_CityCouncil_Deadline, set_ipc_CityCouncil_Deadline] = useState("");

  // Planning Process
  var [process_Documents_Deadline, set_process_Documents_Deadline] =
    useState("");
  var [process_CityCouncil_Deadline, set_process_CityCouncil_Deadline] =
    useState("");

  // Public Consultation
  var [pc_Documents_Deadline, set_pc_Documents_Deadline] = useState("");
  var [pc_Consultation_Deadline, set_pc_consultation_Deadline] = useState("");
  var [pc_CityCouncil_Deadline, set_pc_CityCouncil_Deadline] = useState("");

  // Final Approval
  var [fa_Documents_Deadline, set_fa_Documents_Deadline] = useState("");
  var [fa_Approval_Deadline, set_fa_Approval_Deadline] = useState("");

  var [prePlanningModal, showPrePlanningModal] = useState(false);
  var [initialConsultationModal, showInitialConsultationModal] =
    useState(false);
  var [planningProcessModal, showPlanningProcessModal] = useState(false);
  var [publicConsultationModal, showPublicConsultationModal] = useState(false);
  var [approvalModal, showApprovalModal] = useState(false);

  // Convert deadline ISO dates into numerical % value to show progress on timeline
  const getDeadlineAsNumber = (deadline) => {
    if (deadline) {
      const deadlineUTC = new Date(deadline);
      const currentDateUTC = new Date(currentDate);
      const diffTime = Math.abs(deadlineUTC - currentDateUTC);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return Math.trunc(100 - (diffDays / 365) * 100) || 0; // get numerical value in %
    } else return 0;
  };

  const setDeadline = async (stage, deadlineEnum) => {
    let variables = {};

    if (stage === "a_preplanning") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: pp_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: pp_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
    } else if (stage === "b_initial_consultation") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: ipc_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "public_consultation")
        variables = {
          date: ipc_Consultation_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: ipc_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
    } else if (stage === "c_planning") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: process_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: process_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
    } else if (stage === "d_final_consultation") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: pc_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "public_consultation")
        variables = {
          date: pc_Consultation_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: pc_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
    } else {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: fa_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: fa_Approval_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails.id,
        };
    }

    await API.graphql(
      graphqlOperation(mutations.createProjectDeadline, {
        input: variables,
      })
    )
      .then((res) => {
        showLoadingCircle(false);
        // close all modals
        showPrePlanningModal(false);
        showInitialConsultationModal(false);
        showPlanningProcessModal(false);
        showPublicConsultationModal(false);
        showApprovalModal(false);
      })
      .catch((error) => console.error(error));
  };

  const createDeadlines = async () => {
    setDeadline("a_preplanning", "documents_handover");
    setDeadline("a_preplanning", "city_council");
    setDeadline("b_initial_consultation", "documents_handover");
    setDeadline("b_initial_consultation", "public_consultation");
    setDeadline("b_initial_consultation", "city_council");
    setDeadline("c_planning", "documents_handover");
    setDeadline("c_planning", "city_council");
    setDeadline("d_final_consultation", "documents_handover");
    setDeadline("d_final_consultation", "public_consultation");
    setDeadline("d_final_consultation", "city_council");
    setDeadline("e_approval", "documents_handover");
    setDeadline("e_approval", "city_council");
  };

  const validateDate = (date, nextDeadlineDate) => {
    if (date && nextDeadlineDate) {
      if (date > nextDeadlineDate) {
        return "Cannot be after the deadline of next stage";
      }
    } else return;
  };
  //-------

  // ------------------------ SET COLLABORATORS ------------------------
  var [assignedDevelopers, setAssignedDevelopers] = useState([]);
  var [assignedAgents, setAssignedAgents] = useState([]);
  var [assignedSMEs, setAssignedSMEs] = useState([]);

  const getWorkflowTemplates = async () => {
    await API.graphql(
      graphqlOperation(queries.listDocumentTemplates, {
        filter: {
          stage: {
            ne: "a_preplanning",
          },
          municipality: {
            eq: municipality,
          },
        },
      })
    )
      .then((res) => {
        setDocumentTemplates(
          res?.data?.listDocumentTemplates?.items.sort((a, b) => {
            const nameA = a.createdAt; // ignore upper and lowercase
            const nameB = b.createdAt; // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
          })
        );
        setSelectedDocuments(
          res?.data?.listDocumentTemplates?.items.sort((a, b) => {
            const nameA = a.createdAt; // ignore upper and lowercase
            const nameB = b.createdAt; // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
          })
        );
      })
      .catch((error) => console.error(error.message));
    await API.graphql(
      graphqlOperation(queries.listTaskTemplates, {
        filter: {
          stage: {
            ne: "a_preplanning",
          },
          municipality: {
            eq: municipality,
          },
        },
      })
    )
      .then((res) => {
        const mySelectedTasks = {};
        stages.forEach((stage) => {
          mySelectedTasks[stage] = res?.data?.listTaskTemplates?.items
            .filter((taskTemplate) => taskTemplate.stage === stage)
            .sort((a, b) => {
              const nameA = a.createdAt; // ignore upper and lowercase
              const nameB = b.createdAt; // ignore upper and lowercase
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0; // names must be equal
            });
        });
        setSelectedTasks({ ...mySelectedTasks });
        setTaskTemplates({ ...mySelectedTasks });
      })
      .catch((error) => console.error(error.message));
  };

  const setProjectWorkflowSet = async (id) => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, workflowSet: true },
      })
    )
      .then((res) => {
        console.log("Successfully updated project worfklow flag!");
      })
      .catch((error) => console.error(error.message));
  };

  const createDocumentsAndTasks = async (projectId) => {
    const documentIds = {};
    for (const documentTemplate of selectedDocuments.toReversed()) {
      await createDocument(projectId, documentTemplate, documentIds);
    }

    const documentTasks = [];
    for (const [key, taskTemplatesToBeCreated] of Object.entries(
      selectedTasks
    )) {
      for (const taskTemplate of taskTemplatesToBeCreated.toReversed()) {
        await createTask(projectId, taskTemplate, documentIds, documentTasks);
      }
    }
    for (const documentTask of documentTasks) {
      await assignDocumentTask(documentTask);
    }
  };
  const assignDocumentTask = async ({ documentId, taskId }) => {
    await API.graphql(
      graphqlOperation(mutations.createDocumentTasks, {
        input: { documentId, taskId },
      })
    )
      .then((res) => {
        console.log("Successfully created new task document assignment!");
      })
      .catch((error) => console.error(error.message));
  };

  const createTask = async (
    projectId,
    taskTemplate,
    documentIds,
    documentTasks
  ) => {
    const task = {
      projectId,
      title: taskTemplate.taskTitle,
      mandatory: taskTemplate?.mandatory || false,
      // description: taskTemplate.taskDescription,
      description: taskTemplate?.taskDescription,
      stage: taskTemplate.stage,
      done: false,
      ownerGroups: projectDetails.municipalities,
    };
    if (taskTemplate.assignedTo === "agents") {
      task["assigneeId"] = userDetails.id;
    } else {
      task["assigneeId"] = projectDetails.mainDeveloperId;
      task["owners"] = [userDetails.id, projectDetails.mainDeveloperId];
    }

    await API.graphql(
      graphqlOperation(mutations.createTask, {
        input: task,
      })
    )
      .then((res) => {
        console.log("Successfully created new task!");
        if (taskTemplate.relevantDocuments) {
          for (const relevantDocument of taskTemplate.relevantDocuments) {
            if (documentIds[relevantDocument]) {
              documentTasks.push({
                documentId: documentIds[relevantDocument],
                taskId: res.data.createTask.id,
              });
            }
          }
        }
      })
      .catch((error) => console.error(error.message));
  };

  const createDocument = async (projectId, documentTemplate, documentIds) => {
    const document = {
      projectId,
      name: documentTemplate.documentName,
      // description: documentTemplate.documentDescription,
      description: null,
      mandatory: documentTemplate.documentMandatory,
      stage: documentTemplate.stage,
      status: "a_upload",
      ownerGroups: projectDetails.municipalities,
      publishable: documentTemplate?.publishable || false,
      reviewerId: userDetails.id,
    };
    if (documentTemplate.assignedTo === "agents") {
      document["assigneeId"] = userDetails.id;
    } else {
      document["assigneeId"] = projectDetails.mainDeveloperId;
      document["owners"] = [userDetails.id, projectDetails.mainDeveloperId];
    }

    await API.graphql(
      graphqlOperation(mutations.createDocument, {
        input: document,
      })
    )
      .then((res) => {
        console.log("Successfully created new document!");
        documentIds[documentTemplate.documentName] = res.data.createDocument.id;
      })
      .catch((error) => console.error(error.message));
  };

  const listPersonas = async () => {
    await API.graphql(graphqlOperation(queries.listPersonas))
      .then((res) => {
        setAssigneeOptions(res.data.listPersonas.items);
      })
      .catch((error) => console.error(error));
  };

  const setCollaborators = async () => {
    // Set Developers collaborators
    for (var developer of assignedDevelopers) {
      await API.graphql(
        graphqlOperation(mutations.createProjectDevelopers, {
          input: {
            personaId: developer.value,
            projectId: projectDetails.id,
          },
        })
      )
        .then((res) => {
          console.log("Successfully set collaborator developers");
        })
        .catch((error) => console.error(error));
    }

    // Set Agents collaborators
    for (var agent of assignedAgents) {
      await API.graphql(
        graphqlOperation(mutations.createProjectAgents, {
          input: {
            personaId: agent.value,
            projectId: projectDetails.id,
          },
        })
      )
        .then((res) => {
          console.log("Successfully set collaborator agents");
        })
        .catch((error) => console.error(error));
    }

    // Set SMEs collaborators
    for (var sme of assignedSMEs) {
      await API.graphql(
        graphqlOperation(mutations.createProjectSmes, {
          input: {
            personaId: sme.value,
            projectId: projectDetails.id,
          },
        })
      )
        .then((res) => {
          console.log("Successfully set collaborator SMEs");
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    getProjectDetails();
    listPersonas();
  }, []);

  useEffect(() => {}, [screen]);
  const navigate = useNavigate();

  useEffect(() => {
    getWorkflowTemplates();
  }, [municipality]);

  return (
    <Authenticator>
      {
        // Only Developers can see this page
        userDetails && userDetails.role && userDetails.role !== "agents" ? (
          <AccessDenied />
        ) : (
          <>
            {/* Data Privacy disclaimer popup */}
            {userDetails.dateOfPrivacyConsent === null && <DataPrivacyPopup />}

            <NavigationBar />
            <AppLayout
              navigationHide={true}
              toolsHide={true}
              content={
                <ContentLayout
                  header={
                    <SpaceBetween size="l">
                      <div />
                      <BreadcrumbGroup
                        ariaLabel="Breadcrumbs"
                        items={[
                          { text: t("Dashboard"), href: "/dashboard" },
                          {
                            text: t("Project Dashboard"),
                            href: "/project/" + id,
                          },
                          {
                            text: t("Permitting Workflow Setup"),
                            href: "#",
                          },
                        ]}
                      />
                      <Header
                        variant="h1"
                        description={t(
                          "Set up an individual workflow as standardised template for your own work"
                        )}
                      >
                        {t("Permitting Workflow Setup")}
                      </Header>
                      <div />
                    </SpaceBetween>
                  }
                >
                  {screen === 1 ? (
                    // ------------------------------------ SCREEN 1: CHOOSE TEMPLATE ------------------------------------
                    <>
                      <Container
                        header={
                          <Header variant="h1">{t("Select Template")}</Header>
                        }
                      >
                        <ColumnLayout columns={2} borders="vertical">
                          {/* -------------- Predefined template -------------- */}
                          <div
                            style={{
                              textAlign: "center",
                              padding: 70,
                            }}
                          >
                            <img
                              alt="predefined template"
                              src={PredefinedTemplateImage}
                              style={{ width: "100%" }}
                            />
                            <br />
                            <Button
                              variant={templateType && "primary"}
                              onClick={() => {
                                setTemplateType(true);
                              }}
                            >
                              {t("Use predefined template")}
                            </Button>
                            {templateType && (
                              <div
                                style={{
                                  textAlign: "left",
                                  marginLeft: "30%",
                                  marginTop: 50,
                                }}
                              >
                                <p style={{ fontWeight: "bold" }}>
                                  Select municipality
                                </p>
                                <RadioGroup
                                  onChange={({ detail }) =>
                                    setMunicipality(detail.value)
                                  }
                                  value={municipality}
                                  items={[
                                    {
                                      value: "_820",
                                      label: "Vesthimmerland Kommune",
                                    },
                                    {
                                      value: "_550",
                                      label: "Tønder Kommune",
                                    },
                                  ]}
                                />
                              </div>
                            )}
                          </div>

                          {/* -------------- Own template -------------- */}
                          <div style={{ textAlign: "center", padding: 70 }}>
                            <img
                              alt="predefined template"
                              src={OwnTemplateImage}
                              style={{ width: "90%", marginBottom: 45 }}
                            />
                            <br />
                            <div style={{ color: "gray" }}>
                              <Button disabled>
                                {t("Create my own template")}
                              </Button>
                              <p> {t("Coming soon")}</p>
                            </div>
                          </div>
                        </ColumnLayout>
                      </Container>

                      {templateType && (
                        <div style={{ marginTop: 30, float: "right" }}>
                          <Button
                            variant="primary"
                            onClick={() => {
                              setScreen(2);
                            }}
                          >
                            {t("Next: Set Required Documents")}
                          </Button>
                        </div>
                      )}
                    </>
                  ) : screen === 2 ? (
                    // ------------------------------------ SCREEN 2: REQUIRED DOCUMENTS LIST ------------------------------------
                    <>
                      <RequiredDocumentsList
                        t={t}
                        tasks={tasks}
                        documents={documentTemplates}
                        setDocumentTemplates={setDocumentTemplates}
                        municipality={municipality}
                        selectedDocuments={selectedDocuments}
                        setSelectedDocuments={setSelectedDocuments}
                        taskTemplates={taskTemplates}
                        setSelectedTasks={setSelectedTasks}
                        assigneeOptions={assigneeOptions}
                        projectDetails={projectDetails}
                      />
                      <div style={{ marginTop: 30, float: "right" }}>
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" href={`/project/${id}`}>
                            {t("Cancel")}
                          </Button>
                          <Button onClick={() => setScreen(1)}>
                            {t("Back to Templates")}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              setScreen(3);
                            }}
                          >
                            {t("Next: Set Tasks")}
                          </Button>
                        </SpaceBetween>
                      </div>
                    </>
                  ) : screen === 3 ? (
                    // ------------------------------------ SCREEN 3: SET TASKS ------------------------------------
                    <>
                      <SetTasks
                        t={t}
                        selectedTasks={selectedTasks}
                        setSelectedTasks={setSelectedTasks}
                        tasks={taskTemplates}
                        documents={documentTemplates}
                        setTaskTemplates={setTaskTemplates}
                        assigneeOptions={assigneeOptions}
                        projectDetails={projectDetails}
                        municipality={municipality}
                      />
                      <div style={{ marginTop: 30, float: "right" }}>
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" href="/dashboard">
                            {t("Cancel")}
                          </Button>
                          <Button onClick={() => setScreen(2)}>
                            {t("Back to Documents")}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={async () => {
                              setScreen(4);
                            }}
                            disabled={workflowPending}
                          >
                            {workflowPending
                              ? t("Please wait") + "..."
                              : t("Next: Set Milestones Deadlines")}
                          </Button>
                        </SpaceBetween>
                      </div>
                    </>
                  ) : screen === 4 ? (
                    // ------------------------------------ SCREEN 4: SET MILESTONE DEADLINES ------------------------------------
                    <>
                      {/* Rendering this here instead of using child component so that the states can be maintained if user switches between screens
                           If using child component, all deadline states will be lost if user switches between screens */}
                      <Container
                        variant="stacked"
                        header={
                          <Header
                            variant="h2"
                            description="Here you can set deadline for each milestone. This can be updated later in the Project Dashboad."
                          >
                            {t("Set Milestone Deadlines")}
                          </Header>
                        }
                      >
                        <p style={{ color: darkGray }}>
                          Please set the deadlines in order from{" "}
                          <b>left to right</b>, starting with Documents Review
                          in Pre-planning.
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* ------------------------- PRE-PLANNING ---------------------------- */}
                          <div>
                            <h3>{t("Pre-planning")}</h3>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* Documents review */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  pp_Documents_Deadline
                                )}
                              />
                              {/* City council meeting */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  pp_CityCouncil_Deadline
                                )}
                              />
                            </div>
                            <div style={{ height: 20 }} />
                            <Button onClick={() => showPrePlanningModal(true)}>
                              {t("Set deadlines")}
                            </Button>
                            <Modal
                              header={t("Pre-planning")}
                              onDismiss={() => showPrePlanningModal(false)}
                              visible={prePlanningModal}
                              footer={
                                <>
                                  <Box float="left">
                                    {loadingCircle && (
                                      <StatusIndicator type="loading">
                                        {t("Saving changes")}...
                                      </StatusIndicator>
                                    )}
                                  </Box>
                                  <Box float="right">
                                    <SpaceBetween
                                      direction="horizontal"
                                      size="xs"
                                    >
                                      <Button variant="link">
                                        {t("Cancel")}
                                      </Button>
                                      <Button
                                        variant="primary"
                                        disabled={
                                          !(
                                            pp_Documents_Deadline &&
                                            pp_CityCouncil_Deadline
                                          )
                                        }
                                        onClick={() => {
                                          showPrePlanningModal(false);
                                        }}
                                      >
                                        {t("Save Changes")}
                                      </Button>
                                    </SpaceBetween>
                                  </Box>
                                </>
                              }
                            >
                              {t("Set deadlines")}
                              <div style={{ height: 30 }} />
                              <ColumnLayout columns={2}>
                                <FormField
                                  label={t("Documents Review")}
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    pp_Documents_Deadline,
                                    pp_CityCouncil_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      // for some reason it only allows selecting date starting tomorrow
                                      // so we have to  minus a day to be able to select today's date
                                      date >=
                                      new Date(
                                        new Date().getTime() -
                                          24 * 60 * 60 * 1000
                                      )
                                    }
                                    onChange={({ detail }) => {
                                      set_pp_Documents_Deadline(
                                        (pp_Documents_Deadline = detail.value)
                                      );
                                    }}
                                    value={pp_Documents_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label={t("City Council Meeting")}
                                  constraintText="YYYY/MM/DD"
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(pp_Documents_Deadline)
                                    }
                                    onChange={({ detail }) => {
                                      set_pp_CityCouncil_Deadline(
                                        (pp_CityCouncil_Deadline = detail.value)
                                      );
                                    }}
                                    value={pp_CityCouncil_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                              </ColumnLayout>
                            </Modal>
                          </div>
                          {/* -------------------- INITIAL PUBLIC CONSULTATION --------------------*/}
                          <div>
                            <h3>{t("Initial Public Consultation")}</h3>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* Documents review */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  ipc_Documents_Deadline
                                )}
                              />
                              {/* Public consultation */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  ipc_Consultation_Deadline
                                )}
                              />
                              {/* City council meeting */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  ipc_CityCouncil_Deadline
                                )}
                              />
                            </div>
                            <div style={{ height: 20 }} />
                            <Button
                              onClick={() => showInitialConsultationModal(true)}
                            >
                              {t("Set deadlines")}
                            </Button>
                            <Modal
                              header={t("Initial Public Consultation")}
                              size="large"
                              onDismiss={() =>
                                showInitialConsultationModal(false)
                              }
                              visible={initialConsultationModal}
                              footer={
                                <Box float="right">
                                  <SpaceBetween
                                    direction="horizontal"
                                    size="xs"
                                  >
                                    <Button variant="link">
                                      {t("Cancel")}
                                    </Button>
                                    <Button
                                      variant="primary"
                                      disabled={
                                        !(
                                          ipc_Documents_Deadline &&
                                          ipc_Consultation_Deadline &&
                                          ipc_CityCouncil_Deadline
                                        )
                                      }
                                      onClick={() => {
                                        showInitialConsultationModal(false);
                                      }}
                                    >
                                      {t("Save Changes")}
                                    </Button>
                                  </SpaceBetween>
                                </Box>
                              }
                            >
                              {t("Set deadlines")}
                              <div style={{ height: 30 }} />
                              <ColumnLayout columns={3}>
                                <FormField
                                  label={t("Documents Review")}
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    ipc_Documents_Deadline,
                                    ipc_Consultation_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(pp_CityCouncil_Deadline)
                                    }
                                    onChange={({ detail }) =>
                                      set_ipc_Documents_Deadline(
                                        (ipc_Documents_Deadline = detail.value)
                                      )
                                    }
                                    value={ipc_Documents_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label="Public Consultation"
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    ipc_Consultation_Deadline,
                                    ipc_CityCouncil_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(ipc_Documents_Deadline)
                                    }
                                    onChange={({ detail }) =>
                                      set_ipc_consultation_Deadline(
                                        (ipc_Consultation_Deadline =
                                          detail.value)
                                      )
                                    }
                                    value={ipc_Consultation_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label={t("City Council Meeting")}
                                  constraintText="YYYY/MM/DD"
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >=
                                      new Date(ipc_Consultation_Deadline)
                                    }
                                    onChange={({ detail }) =>
                                      set_ipc_CityCouncil_Deadline(
                                        (ipc_CityCouncil_Deadline =
                                          detail.value)
                                      )
                                    }
                                    value={ipc_CityCouncil_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                              </ColumnLayout>
                            </Modal>
                          </div>
                          {/* ---------------------------- PLANNING PROCESS ----------------------------*/}
                          <div>
                            <h3>{t("Planning Process")}</h3>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* Documents review */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  process_Documents_Deadline
                                )}
                              />
                              {/* City council meeting */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  process_CityCouncil_Deadline
                                )}
                              />
                            </div>
                            <div style={{ height: 20 }} />
                            <Button
                              onClick={() => showPlanningProcessModal(true)}
                            >
                              {t("Set deadlines")}
                            </Button>
                            <Modal
                              onDismiss={() => showPlanningProcessModal(false)}
                              visible={planningProcessModal}
                              footer={
                                <>
                                  <Box float="left">
                                    {loadingCircle && (
                                      <StatusIndicator type="loading">
                                        {t("Saving changes")}...
                                      </StatusIndicator>
                                    )}
                                  </Box>
                                  <Box float="right">
                                    <SpaceBetween
                                      direction="horizontal"
                                      size="xs"
                                    >
                                      <Button variant="link">
                                        {t("Cancel")}
                                      </Button>
                                      <Button
                                        variant="primary"
                                        disabled={
                                          !(
                                            process_Documents_Deadline &&
                                            process_CityCouncil_Deadline
                                          )
                                        }
                                        onClick={() => {
                                          showPlanningProcessModal(false);
                                        }}
                                      >
                                        {t("Save Changes")}
                                      </Button>
                                    </SpaceBetween>
                                  </Box>
                                </>
                              }
                              header={t("Planning Process")}
                            >
                              {t("Set deadlines")}
                              <div style={{ height: 30 }} />
                              <ColumnLayout columns={2}>
                                <FormField
                                  label={t("Documents Review")}
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    process_Documents_Deadline,
                                    process_CityCouncil_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(ipc_CityCouncil_Deadline)
                                    }
                                    onChange={({ detail }) => {
                                      set_process_Documents_Deadline(
                                        (process_Documents_Deadline =
                                          detail.value)
                                      );
                                    }}
                                    value={process_Documents_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label={t("City Council Meeting")}
                                  constraintText="YYYY/MM/DD"
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >=
                                      new Date(process_Documents_Deadline)
                                    }
                                    onChange={({ detail }) => {
                                      set_process_CityCouncil_Deadline(
                                        (process_CityCouncil_Deadline =
                                          detail.value)
                                      );
                                    }}
                                    value={process_CityCouncil_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                              </ColumnLayout>
                            </Modal>
                          </div>
                          {/* ---------------------------- FINAL CONSULTATION ----------------------------*/}
                          <div>
                            <h3>{t("Final Public Consultation")}</h3>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* Documents review */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  pc_Documents_Deadline
                                )}
                              />
                              {/* Public consultation */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  pc_Consultation_Deadline
                                )}
                              />
                              {/* City council meeting */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  pc_CityCouncil_Deadline
                                )}
                              />
                            </div>
                            <div style={{ height: 20 }} />
                            <Button
                              onClick={() => showPublicConsultationModal(true)}
                            >
                              {t("Set deadlines")}
                            </Button>
                            <Modal
                              header={t("Final Public Consultation")}
                              size="large"
                              onDismiss={() =>
                                showPublicConsultationModal(false)
                              }
                              visible={publicConsultationModal}
                              footer={
                                <Box float="right">
                                  <SpaceBetween
                                    direction="horizontal"
                                    size="xs"
                                  >
                                    <Button variant="link">
                                      {t("Cancel")}
                                    </Button>
                                    <Button
                                      variant="primary"
                                      disabled={
                                        !(
                                          pc_Documents_Deadline &&
                                          pc_Consultation_Deadline &&
                                          pc_CityCouncil_Deadline
                                        )
                                      }
                                      onClick={() => {
                                        showPublicConsultationModal(false);
                                      }}
                                    >
                                      {t("Save Changes")}
                                    </Button>
                                  </SpaceBetween>
                                </Box>
                              }
                            >
                              {t("Set deadlines")}
                              <div style={{ height: 30 }} />
                              <ColumnLayout columns={3}>
                                <FormField
                                  label={t("Documents Review")}
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    pc_Documents_Deadline,
                                    pc_CityCouncil_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >=
                                      new Date(process_CityCouncil_Deadline)
                                    }
                                    onChange={({ detail }) =>
                                      set_pc_Documents_Deadline(
                                        (pc_Documents_Deadline = detail.value)
                                      )
                                    }
                                    value={pc_Documents_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label="Public Consultation"
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    pc_Consultation_Deadline,
                                    pc_CityCouncil_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(pc_Documents_Deadline)
                                    }
                                    onChange={({ detail }) =>
                                      set_pc_consultation_Deadline(
                                        (pc_Consultation_Deadline =
                                          detail.value)
                                      )
                                    }
                                    value={pc_Consultation_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label={t("City Council Meeting")}
                                  constraintText="YYYY/MM/DD"
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(pc_Consultation_Deadline)
                                    }
                                    onChange={({ detail }) =>
                                      set_pc_CityCouncil_Deadline(
                                        (pc_CityCouncil_Deadline = detail.value)
                                      )
                                    }
                                    value={pc_CityCouncil_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                              </ColumnLayout>
                            </Modal>
                          </div>
                          {/* ---------------------------- APPROVAL ----------------------------*/}
                          <div>
                            <h3>{t("Final Approval")}</h3>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* Documents review */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  fa_Documents_Deadline
                                )}
                              />
                              {/* Appeal */}
                              <LinearProgress
                                sx={{
                                  height: 10,
                                  width: "100%",
                                  borderRadius: 0,
                                  [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: lightGray,
                                    borderRight: "1px solid",
                                  },
                                  // progress colour
                                  [`& .${linearProgressClasses.bar}`]: {
                                    borderRadius: 0,
                                    backgroundColor: primaryBlue,
                                  },
                                }}
                                variant="determinate"
                                value={getDeadlineAsNumber(
                                  fa_Approval_Deadline
                                )}
                              />
                            </div>
                            <div style={{ height: 20 }} />
                            <Button onClick={() => showApprovalModal(true)}>
                              {t("Set deadlines")}
                            </Button>
                            <Modal
                              header={t("Final Approval")}
                              onDismiss={() => showApprovalModal(false)}
                              visible={approvalModal}
                              footer={
                                <>
                                  <Box float="left">
                                    {loadingCircle && (
                                      <StatusIndicator type="loading">
                                        {t("Saving changes")}...
                                      </StatusIndicator>
                                    )}
                                  </Box>
                                  <Box float="right">
                                    <SpaceBetween
                                      direction="horizontal"
                                      size="xs"
                                    >
                                      <Button variant="link">
                                        {t("Cancel")}
                                      </Button>
                                      <Button
                                        variant="primary"
                                        disabled={
                                          !(
                                            fa_Documents_Deadline &&
                                            fa_Approval_Deadline
                                          )
                                        }
                                        onClick={() => {
                                          showApprovalModal(false);
                                        }}
                                      >
                                        {t("Save Changes")}
                                      </Button>
                                    </SpaceBetween>
                                  </Box>
                                </>
                              }
                            >
                              {t("Set deadlines")}
                              <div style={{ height: 30 }} />
                              <ColumnLayout columns={2}>
                                <FormField
                                  label={t("Documents Review")}
                                  constraintText="YYYY/MM/DD"
                                  errorText={validateDate(
                                    fa_Documents_Deadline,
                                    fa_Approval_Deadline
                                  )}
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(pc_CityCouncil_Deadline)
                                    }
                                    onChange={({ detail }) => {
                                      set_fa_Documents_Deadline(
                                        (fa_Documents_Deadline = detail.value)
                                      );
                                    }}
                                    value={fa_Documents_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                                <FormField
                                  label={t("City Council Meeting")}
                                  constraintText="YYYY/MM/DD"
                                >
                                  <DatePicker
                                    isDateEnabled={(date) =>
                                      date >= new Date(fa_Documents_Deadline)
                                    }
                                    onChange={({ detail }) => {
                                      set_fa_Approval_Deadline(
                                        (fa_Approval_Deadline = detail.value)
                                      );
                                    }}
                                    value={fa_Approval_Deadline}
                                    openCalendarAriaLabel={(selectedDate) =>
                                      `selected date is ${selectedDate}`
                                    }
                                    placeholder="YYYY/MM/DD"
                                  />
                                </FormField>
                              </ColumnLayout>
                            </Modal>
                          </div>
                        </div>
                      </Container>
                      <div style={{ marginTop: 30, float: "right" }}>
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" href="/dashboard">
                            {t("Cancel")}
                          </Button>
                          <Button onClick={() => setScreen(3)}>
                            {t("Back to Tasks")}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              setScreen(5);
                            }}
                          >
                            {t("Next: Set Collaborators")}
                          </Button>
                        </SpaceBetween>
                      </div>
                    </>
                  ) : (
                    // ------------------------------------ SCREEN 5: SET COLLABORATORS ------------------------------------
                    <>
                      <Container
                        variant="stacked"
                        header={
                          <Header
                            variant="h2"
                            description={t(
                              "As the concluding stage, you have the option to assign collaborators to specific roles within the project. This assignment can be adjusted at a later point, and new team members may also be updated if needed."
                            )}
                          >
                            {t("Set Collaborators")}
                          </Header>
                        }
                      >
                        <div style={{ marginTop: 20 }} />
                        {/* Main project agent and developer */}
                        <ColumnLayout columns={2}>
                          <div>
                            <b>{t("Project Developer")}</b>
                            <br />
                            {projectDetails?.mainDeveloper ? (
                              <Popover
                                position="top"
                                dismissButton={false}
                                size="medium"
                                triggerType="custom"
                                header={
                                  projectDetails?.mainDeveloper?.given_name +
                                  " " +
                                  projectDetails?.mainDeveloper?.family_name
                                }
                                content={
                                  <SpaceBetween size="s">
                                    <Link>
                                      {projectDetails?.mainDeveloper?.email}
                                    </Link>
                                    <span>
                                      {
                                        projectDetails?.mainDeveloper
                                          ?.phone_number
                                      }
                                    </span>
                                    <span>
                                      {projectDetails?.mainAgent?.role ===
                                      "agents"
                                        ? "Agent"
                                        : "Developer"}
                                    </span>
                                    <span>
                                      {t("Municipality")}:{" "}
                                      {MapCodeToLabel(
                                        projectDetails?.mainAgent?.municipality
                                      ) || "None"}
                                    </span>
                                  </SpaceBetween>
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "pointer",
                                  }}
                                >
                                  <AccountCircleIcon
                                    style={{ marginRight: 5 }}
                                  />
                                  <b style={{ color: primaryBlue }}>
                                    {projectDetails?.mainDeveloper?.given_name +
                                      " " +
                                      projectDetails?.mainDeveloper
                                        ?.family_name}
                                  </b>
                                </div>
                              </Popover>
                            ) : (
                              <i style={{ color: "gray" }}>
                                {t("Not yet assigned")}
                              </i>
                            )}
                          </div>
                          <div>
                            <b>{t("Project Agent")}</b>
                            <br />
                            {projectDetails?.mainAgent ? (
                              <Popover
                                position="top"
                                dismissButton={false}
                                size="medium"
                                triggerType="custom"
                                header={
                                  projectDetails?.mainAgent?.given_name +
                                  " " +
                                  projectDetails?.mainAgent?.family_name
                                }
                                content={
                                  <SpaceBetween size="s">
                                    <Link>
                                      {projectDetails?.mainAgent?.email}
                                    </Link>
                                    <span>
                                      {projectDetails?.mainAgent?.phone_number}
                                    </span>
                                    <span>
                                      {projectDetails?.mainAgent?.role ===
                                      "agents"
                                        ? "Agent"
                                        : "Developer"}
                                    </span>
                                    <span>
                                      {t("Municipality")}:{" "}
                                      {MapCodeToLabel(
                                        projectDetails?.mainAgent?.municipality
                                      ) || "None"}
                                    </span>
                                  </SpaceBetween>
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "pointer",
                                  }}
                                >
                                  <AccountCircleIcon
                                    style={{ marginRight: 5 }}
                                  />
                                  <b style={{ color: primaryBlue }}>
                                    {projectDetails?.mainAgent?.given_name +
                                      " " +
                                      projectDetails?.mainAgent?.family_name}
                                  </b>
                                </div>
                              </Popover>
                            ) : (
                              <i style={{ color: "gray" }}>
                                {t("Not yet assigned")}
                              </i>
                            )}
                          </div>
                        </ColumnLayout>
                        <ColumnLayout columns={3}>
                          <div>
                            <h3>{t("Project Developer")}</h3>
                            <Multiselect
                              disabled={userDetails.role !== "developers"}
                              placeholder={t("Choose developer(s)")}
                              selectedAriaLabel="developers selected"
                              selectedOptions={assignedDevelopers}
                              onChange={({ detail }) => {
                                setAssignedDevelopers(
                                  (assignedDevelopers = detail.selectedOptions)
                                );
                              }}
                              options={assigneeOptions
                                .filter(
                                  (assignee) => assignee.role === "developers"
                                )
                                .map((assignee) => ({
                                  value: assignee?.id,
                                  label: `${assignee?.given_name} ${assignee?.family_name}`,
                                }))}
                            />
                          </div>
                          <div>
                            <h3>{t("Permitting Agent")}</h3>
                            <Multiselect
                              disabled={userDetails.role === "developers"}
                              placeholder={t("Choose agents(s)")}
                              selectedAriaLabel="agents selected"
                              selectedOptions={assignedAgents}
                              onChange={({ detail }) => {
                                setAssignedAgents(
                                  (assignedAgents = detail.selectedOptions)
                                );
                              }}
                              options={assigneeOptions
                                .filter(
                                  (assignee) =>
                                    assignee.role === "agents" &&
                                    assignee.domain === userDetails.domain
                                )
                                .map((assignee) => ({
                                  value: assignee?.id,
                                  label: `${assignee?.given_name} ${assignee?.family_name}`,
                                }))}
                            />
                          </div>
                          <div>
                            <h3>{t("SME Reviewer")}</h3>
                            <Multiselect
                              disabled={userDetails.role === "developers"}
                              placeholder={t("Choose SME(s)")}
                              selectedAriaLabel="SMEs selected"
                              selectedOptions={assignedSMEs}
                              onChange={({ detail }) => {
                                setAssignedSMEs(
                                  (assignedSMEs = detail.selectedOptions)
                                );
                              }}
                              options={assigneeOptions
                                .filter(
                                  (assignee) =>
                                    assignee.role === "agents" &&
                                    assignee.domain === userDetails.domain
                                )
                                .map((assignee) => ({
                                  value: assignee?.id,
                                  label: `${assignee?.given_name} ${assignee?.family_name}`,
                                }))}
                            />
                          </div>
                        </ColumnLayout>
                      </Container>
                      <div style={{ height: 30 }} />
                      {loadingCircle && (
                        <Box float="left">
                          <CircularProgress size={20} />
                          <span style={{ paddingLeft: 10 }}>
                            <b>{t("Saving changes")}...</b>
                          </span>
                        </Box>
                      )}
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" href="/dashboard">
                            {t("Cancel")}
                          </Button>
                          <Button onClick={() => setScreen(4)}>
                            {t("Back to Milestones deadlines")}
                          </Button>
                          <Button
                            variant="primary"
                            disabled={workflowPending}
                            onClick={async () => {
                              showLoadingCircle(true);
                              setWorkflowPending(true);
                              await setProjectWorkflowSet(id);
                              await createDocumentsAndTasks(id);
                              await createDeadlines();
                              await setCollaborators();
                              navigate("/project/" + id);
                            }}
                          >
                            {workflowPending
                              ? t("Please wait") + "..."
                              : t("Setup Workflow")}
                          </Button>
                        </SpaceBetween>
                      </Box>
                    </>
                  )}
                </ContentLayout>
              }
            />
          </>
        )
      }
    </Authenticator>
  );
}

const RequiredDocumentsList = (props) => {
  // Create new document modal
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [newDocumentModal, showNewDocumentModal] = useState(false);
  const [assignee, setAssignee] = useState({
    label: props.t("agents"),
    value: "agents",
  });
  const t = props.t;
  const [stage, setStage] = useState({
    value: "",
    label: "None selected",
  });

  return (
    props.documents && (
      <Container
        variant="stacked"
        header={
          <Header
            variant="h2"
            description={t(
              "We set the documents of the request that are important to you. Use the checklist icon on the left to design your individual workflow."
            )}
          >
            {t("Required Documents List")}
          </Header>
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: -40,
            marginBottom: 30,
          }}
        >
          <Button
            iconAlign="left"
            iconName="add-plus"
            onClick={() => {
              showNewDocumentModal(true);
            }}
          >
            {t("Add New Document")}
          </Button>
        </div>

        {/* -------------- ADD DOCUMENT MODAL -------------- */}
        <Modal
          onDismiss={() => showNewDocumentModal(false)}
          visible={newDocumentModal}
          closeAriaLabel="Close modal"
          header={t("Add New Document")}
          footer={
            <>
              {
                // Show loading circle when user clicks Save Changes
                loadingCircle && (
                  <Box float="left">
                    <StatusIndicator type="loading">
                      {t("Saving changes")}...
                    </StatusIndicator>
                  </Box>
                )
              }
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={() => showNewDocumentModal(false)}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={
                      documentName === "" ||
                      stage.value === "" ||
                      assignee.value === "" ||
                      props.documents
                        .map((document) => document.documentName)
                        .includes(documentName)
                    }
                    onClick={() => {
                      showLoadingCircle(true);
                      props.setDocumentTemplates([
                        ...props.documents,
                        {
                          documentName,
                          documentMandatory: false,
                          assignedTo: assignee.value,
                          stage: stage.value,
                          municipality: props.municipality,
                        },
                      ]);
                      props.setSelectedDocuments([
                        ...props.documents,
                        {
                          documentName,
                          documentMandatory: false,
                          assignedTo: "agents",
                          stage: stage.value,
                          municipality: props.municipality,
                        },
                      ]);
                      showNewDocumentModal(false);
                    }}
                  >
                    {t("Add New Document")}
                  </Button>
                </SpaceBetween>
              </Box>
            </>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <FormField label={t("Document Name")}>
              <Input
                placeholder={t("Enter a name here...")}
                value={documentName}
                onChange={(event) => setDocumentName(event.detail.value)}
              />
            </FormField>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <FormField label={t("Stage")}>
                <Select
                  selectedAriaLabel="document stage selected"
                  selectedOption={stage}
                  onChange={({ detail }) => setStage(detail.selectedOption)}
                  options={[
                    {
                      value: "a_preplanning",
                      label: props.t("a_preplanning"),
                    },
                    {
                      value: "b_initial_consultation",
                      label: props.t("b_initial_consultation"),
                    },
                    {
                      value: "c_planning",
                      label: props.t("c_planning"),
                    },
                    {
                      value: "d_final_consultation",
                      label: props.t("d_final_consultation"),
                    },
                    {
                      value: "e_approval",
                      label: props.t("e_approval"),
                    },
                  ]}
                />
              </FormField>
            </Grid>
            <FormField label={t("Assign to")}>
              <Select
                selectedAriaLabel="Assignee selected"
                selectedOption={assignee}
                onChange={({ detail }) => setAssignee(detail.selectedOption)}
                options={[
                  { label: props.t("developers"), value: "developers" },
                  { label: props.t("agents"), value: "agents" },
                ]}
              />
            </FormField>
          </SpaceBetween>
        </Modal>

        <Table
          variant="embedded"
          resizableColumns
          sortingDisabled
          onSelectionChange={({ detail }) => {
            props.setSelectedDocuments(detail.selectedItems);
            const selectedDocumentNames = detail.selectedItems.map(
              (selectedDocument) => selectedDocument.documentName
            );
            const mySelectedTasks = { ...props.taskTemplates };
            for (const [key, value] of Object.entries(mySelectedTasks)) {
              mySelectedTasks[key] = value.filter(
                (taskTemplate) =>
                  taskTemplate?.relevantDocuments?.length !== 1 ||
                  selectedDocumentNames.includes(
                    taskTemplate?.relevantDocuments[0]
                  )
              );
            }

            props.setSelectedTasks({ ...mySelectedTasks });
          }}
          selectedItems={props.selectedDocuments}
          columnDefinitions={[
            {
              id: "stage",
              header: t("Stage"),
              cell: (e) => props.t(e.stage),
              sortingField: "stage",
            },
            {
              id: "documentName",
              header: t("Document"),
              cell: (e) => props.t(e.documentName),
              sortingField: "documentName",
            },

            {
              id: "assignedTo",
              header: t("Owner as role"),
              cell: (e) => props.t(e.assignedTo),
              sortingField: "assignedTo",
            },
          ]}
          items={props.documents.sort((a, b) => {
            const nameA = a.stage; // ignore upper and lowercase
            const nameB = b.stage; // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
          })}
          loadingText="Loading resources"
          selectionType="multi"
          trackBy="documentName"
          isItemDisabled={(item) => item.documentMandatory}
          visibleColumns={["stage", "documentName", "assignedTo"]}
          empty={
            <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No resources to display.
              </Box>
            </Box>
          }
        />
      </Container>
    )
  );
};

const SetTasks = (props) => {
  const [activeTabId, setActiveTabId] = useState("b_initial_consultation");
  const t = props.t;
  // Create new task modal
  const [modal, showModal] = useState(false);
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [stage, setStage] = useState({
    value: "",
    label: t("No stage selected"),
  });
  const [assignee, setAssignee] = useState({
    label: "None assigned",
    value: "",
  });
  const [relatedDocuments, setRelatedDocuments] = useState([]);

  const TabContent = (
    <Table
      variant="embedded"
      sortingDisabled
      onSelectionChange={({ detail }) => {
        const mySelectedTasks = props.selectedTasks;
        mySelectedTasks[activeTabId] = detail.selectedItems;
        props.setSelectedTasks({ ...mySelectedTasks });
      }}
      selectedItems={props.selectedTasks[activeTabId]}
      isItemDisabled={(task) => !task.custom}
      columnDefinitions={[
        {
          id: "taskTitle",
          header: t("Task Name"),
          cell: (e) => props.t(e.taskTitle),
          sortingField: "taskTitle",
        },
        {
          id: "document",
          header: t("Related documents"),
          cell: (e) =>
            e?.relevantDocuments?.length === 1
              ? props.t(e.relevantDocuments[0])
              : "-",
          sortingField: "document",
        },
        {
          id: "assignedTo",
          header: t("Assigned to"),
          cell: (e) => props.t(e.assignedTo),
          sortingField: "assignedTo",
        },
      ]}
      items={props.tasks[activeTabId]}
      loadingText="Loading resources"
      selectionType="multi"
      trackBy="taskTitle"
      visibleColumns={["taskTitle", "document", "assignedTo"]}
      empty={
        <Box textAlign="center" color="inherit">
          <b>{props.t(activeTabId)}</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            {t("No tasks available")}
          </Box>
        </Box>
      }
    />
  );

  return (
    <Container
      variant="stacked"
      header={
        <Header
          variant="h2"
          description={t(
            "We set the tasks of the request that are important to you. Use the checklist icon on the left to design your individual workflow."
          )}
        >
          {t("Set Tasks")}
        </Header>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: -40,
          marginBottom: 30,
        }}
      >
        <Button
          iconAlign="left"
          iconName="add-plus"
          onClick={() => {
            showModal(true);
          }}
        >
          {t("Add New Task")}
        </Button>
      </div>

      {/* Add new task popup */}
      <Modal
        onDismiss={() => showModal(false)}
        visible={modal}
        closeAriaLabel="Close modal"
        header={t("Add New Task")}
        footer={
          <>
            {
              // Show loading circle when user clicks Save Changes
              loadingCircle && (
                <Box float="left">
                  <StatusIndicator type="loading">
                    {t("Saving changes")}...
                  </StatusIndicator>
                </Box>
              )
            }
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => showModal(false)}>
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    showLoadingCircle(true);
                    const myTaskTemplates = { ...props.tasks };
                    myTaskTemplates[stage.value] = [
                      ...myTaskTemplates[stage.value],
                      {
                        taskTitle: taskName,
                        assignedTo: "agents",
                        stage: stage.value,
                        municipality: props.municipality,
                        relevantDocuments: relatedDocuments.map(
                          (doc) => doc.value
                        ),
                        custom: true,
                      },
                    ];
                    props.setTaskTemplates(myTaskTemplates);
                    const mySelectedTasks = { ...props.selectedTasks };
                    mySelectedTasks[stage.value] = [
                      ...mySelectedTasks[stage.value],
                      {
                        taskTitle: taskName,
                        assignedTo: "agents",
                        stage: stage.value,
                        municipality: props.municipality,
                        relevantDocuments: relatedDocuments.map(
                          (doc) => doc.value
                        ),
                        custom: true,
                      },
                    ];
                    props.setSelectedTasks(mySelectedTasks);
                    showModal(false);
                  }}
                  disabled={
                    taskName === "" ||
                    stage.value === "" ||
                    props.tasks[stage.value]
                      .map((taskTemplate) => taskTemplate.taskTitle)
                      .includes(taskName) ||
                    assignee.value === ""
                  }
                >
                  Submit
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <FormField label={t("Task Name")}>
            <Input
              placeholder=""
              value={taskName}
              onChange={(event) => {
                setTaskName(event.detail.value);
              }}
            />
          </FormField>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <FormField label={t("Stage")}>
              <Select
                selectedAriaLabel="Stage selected"
                selectedOption={stage}
                onChange={({ detail }) => {
                  setRelatedDocuments([]);
                  setStage(detail.selectedOption);
                }}
                options={[
                  { label: t("Pre-planning"), value: "a_preplanning" },
                  {
                    label: t("Initial Public Consultation"),
                    value: "b_initial_consultation",
                  },
                  { label: t("Planning Process"), value: "c_planning" },
                  {
                    label: t("Final Consultation"),
                    value: "d_final_consultation",
                  },
                  { label: t("Final Approval"), value: "e_approval" },
                ]}
              />
            </FormField>
          </Grid>
          <FormField label={t("Assigned to")}>
            <Select
              selectedAriaLabel="Assignee selected"
              selectedOption={assignee}
              onChange={({ detail }) => setAssignee(detail.selectedOption)}
              options={[
                { label: props.t("developers"), value: "developers" },
                { label: props.t("agents"), value: "agents" },
              ]}
            />
          </FormField>
          <FormField label={t("Related documents")}>
            <Multiselect
              placeholder={t("Choose documents")}
              selectedAriaLabel="related documents"
              selectedOptions={relatedDocuments}
              onChange={({ detail }) =>
                setRelatedDocuments(detail.selectedOptions)
              }
              options={props.documents
                .filter((document) => document.stage === stage.value)
                .map((document) => ({
                  value: document.documentName,
                  label: props.t(document.documentName),
                }))}
            />
          </FormField>
        </SpaceBetween>
      </Modal>

      <Tabs
        onChange={({ detail }) => {
          setActiveTabId(detail.activeTabId);
        }}
        activeTabId={activeTabId}
        tabs={[
          {
            label: t("Pre-planning"),
            id: "a_preplanning",
            content: TabContent,
          },
          {
            label: t("Initial Public Consultation"),
            id: "b_initial_consultation",
            content: TabContent,
          },
          {
            label: t("Planning Process"),
            id: "c_planning",
            content: TabContent,
          },
          {
            label: t("Public Consultation"),
            id: "d_final_consultation",
            content: TabContent,
          },
          {
            label: t("Final Approval"),
            id: "e_approval",
            content: TabContent,
          },
        ]}
      />
    </Container>
  );
};
