import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const { context } = useAuthenticator((context) => [context]);

  if (route === "authenticated") {
    return children;
  } else {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
}

// source https://ui.docs.amplify.aws/react/guides/auth-protected#amplify-id-9
