import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { SpaceBetween } from "@cloudscape-design/components";
import Tooltip from "@mui/material/Tooltip";

/* ----------------------------- CSS ----------------------------- */
const primaryBlue = "#0972D3";
const hoverBlue = "#075dad";
const blueLink = "#539FE5";
const warningOrange = "#E07941";
const darkGray = "#414D5C";
const lightGray = "#e3e3e3";
const primaryGreen = "#04A50B";
const warningRed = "#D82732";
const currentDate = new Date().toISOString().slice(0, 10);

const Divider = () => {
  return (
    <hr
      style={{
        borderTop: "0.5px solid",
        borderColor: lightGray,
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};

const DividerThick = () => {
  return (
    <hr
      style={{
        borderTop: "1.2px solid",
        borderColor: lightGray,
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};

const VerticalDivider = () => {
  return (
    <hr
      style={{
        borderLeft: "1px solid",
        borderColor: lightGray,
        height: "200px",
        marginLeft: 20,
        marginRight: 20,
      }}
    />
  );
};

/* ----------------------------- FUNCTIONS ----------------------------- */
const isDeadlinePassed = (deadlineDate) => {
  if (deadlineDate !== null) {
    const deadlineUTC = new Date(deadlineDate);
    const currentDateUTC = new Date();
    return deadlineUTC < currentDateUTC; // returns true if deadline has passed
  } else return false;
};

const calculatePriority = (deadline, showDeadline) => {
  let priorityText = "";
  const deadlineUTC = new Date(deadline);
  const currentDateUTC = new Date(currentDate);
  const diffTime = Math.abs(deadlineUTC - currentDateUTC);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const value = Math.trunc(100 - (diffDays / 365) * 100) || "-"; // get numerical value in %

  if (deadline) {
    // Low priority
    if (value <= 50)
      priorityText = (
        <SpaceBetween direction="horizontal">
          <Tooltip title="Low priority">
            <KeyboardControlKeyIcon style={{ color: primaryGreen }} />
          </Tooltip>
          {showDeadline && (deadline || "-")}
        </SpaceBetween>
      );
    // Medium priority
    else if (value > 50 && value <= 80)
      priorityText = (
        <SpaceBetween direction="horizontal">
          <Tooltip title="Medium priority">
            <KeyboardDoubleArrowUpIcon style={{ color: warningOrange }} />
          </Tooltip>
          {showDeadline && (deadline || "-")}
        </SpaceBetween>
      );
    // High priority
    else
      priorityText = (
        <SpaceBetween direction="horizontal">
          <Tooltip title="High priority">
            <KeyboardDoubleArrowUpIcon style={{ color: "#D50000" }} />
          </Tooltip>
          {showDeadline && (deadline || "-")}
        </SpaceBetween>
      );
  } else priorityText = "-";

  return priorityText;
};

// Get deadline dates for popover contents
const getDeadlineDate = (project, stage, deadlineEnum) => {
  let deadlineDate = "";
  if (project.deadlines?.items?.length) {
    // only get latest deadline by sorting by createdAt value and get the first item in the list
    let sortByDate = project.deadlines?.items
      ?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .filter(
        (item) => item.stage === stage && item.deadlineEnum === deadlineEnum
      );
    // Check that field 'date' exists to prevent app from crashing as sometimes new projects won't have this field set
    if (sortByDate[0]?.date) deadlineDate = sortByDate[0]?.date;
  }
  return deadlineDate || <i style={{ color: "gray" }}>Deadline not set yet</i>;
};

const getDeadlineAsNumber = (
  project,
  prevDeadlineStage,
  prevDeadlineEnum,
  currentStage,
  currentDeadlineEnum
) => {
  let numericalValue = 0;
  let sortByDate = "";

  if (project.deadlines?.items?.length) {
    // Get current stage's deadline
    sortByDate = project.deadlines?.items
      ?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .filter(
        (item) =>
          item.stage === currentStage &&
          item.deadlineEnum === currentDeadlineEnum
      );

    // Check that field 'date' exists to prevent app from crashing as sometimes new projects won't have this field set
    if (sortByDate[0]?.date) {
      // For the very first stage (ie. Documents Review in Pre-planning)
      // there's no previous deadline so we compare it against the current date
      if (prevDeadlineStage === null && prevDeadlineEnum === null) {
        const deadlineUTC = new Date(sortByDate[0]?.date);
        const currentDateUTC = new Date(currentDate);
        const diffTime = Math.abs(deadlineUTC - currentDateUTC);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        numericalValue = Math.trunc(100 - (diffDays / 365) * 100); // get numerical value in %
      } else {
        // For all other stages
        // Get the progress by comparing against previous stage's deadline
        let sortByDate_prev = project.deadlines?.items
          ?.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          .filter(
            (item) =>
              item.stage === prevDeadlineStage &&
              item.deadlineEnum === prevDeadlineEnum
          );
        const deadlineUTC = new Date(sortByDate[0]?.date);
        const prevDeadlineUTC = new Date(sortByDate_prev[0].date);
        const currentDateUTC = new Date();

        // If deadline is in the past then progress is full
        if (currentDateUTC > prevDeadlineUTC && currentDateUTC > deadlineUTC) {
          numericalValue = 100;
        } else if (
          // if deadline is in the future then progress is 0
          currentDateUTC < prevDeadlineUTC &&
          currentDateUTC < deadlineUTC
        ) {
          numericalValue = 0;
        } else {
          // If deadline is today's date, then calculate progress
          // by getting the difference between 2 dates, then convert the difference to numerical value over 100%
          const diffTime = Math.abs(currentDateUTC - prevDeadlineUTC);
          const diffTimeDeadlines = Math.abs(deadlineUTC - prevDeadlineUTC);
          numericalValue = Math.trunc((diffTime / diffTimeDeadlines) * 100);
        }
      }
    } else numericalValue = 0;
  }
  return numericalValue || 0;
};

const getProgressColor = (project, stage, deadlineEnum) => {
  var colour = "";
  if (project.deadlines?.items?.length) {
    // only get latest deadline by sorting by createdAt value and get the first item in the list
    let sortByDate = project.deadlines?.items
      ?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .filter(
        (item) => item.stage === stage && item.deadlineEnum === deadlineEnum
      );

    if (sortByDate[0]?.date) {
      const delayedTasksExist = project?.tasks?.items.filter(
        (task) =>
          task.stage === project.stage &&
          task.deadline &&
          !task.done &&
          isDeadlinePassed(task.deadline)
      ).length;
      const delayedDocumentsExist = project?.documents?.items.filter(
        (document) =>
          document.stage === project.stage &&
          document.deadline &&
          document.status !== "d_done" &&
          isDeadlinePassed(document.deadline)
      ).length;

      // check if stage < project stage by comparing the first letter
      if (stage[0] === project.stage[0]) {
        // show warning orange if there are either delayed tasks or documents
        colour =
          delayedTasksExist || delayedDocumentsExist
            ? warningOrange
            : primaryBlue;
      } else {
        if (stage[0] < project.stage[0])
          colour = primaryGreen; // for past stages show green (done)
        else colour = lightGray; // for future stages show blue
      }
    } else colour = "";
  }
  return colour;
};

const denmarkMunicipalityList = [
  // {
  //   value: "_101",
  //   label: "Copenhagen",
  //   website: "https://www.kk.dk",
  // },
  // {
  //   value: "_751",
  //   label: "Aarhus",
  //   website: "https://www.aarhus.dk",
  // },
  // {
  //   value: "_851",
  //   label: "Aalborg",
  // },
  // {
  //   value: "_461",
  //   label: "Odense",
  // },
  // {
  //   value: "_561",
  //   label: "Esbjerg",
  // },
  // {
  //   value: "_630",
  //   label: "Vejle",
  // },
  // {
  //   value: "_147",
  //   label: "Frederiksberg",
  // },
  // {
  //   value: "_730",
  //   label: "Randers",
  // },
  // {
  //   value: "_791",
  //   label: "Viborg",
  // },
  // {
  //   value: "_621",
  //   label: "Kolding",
  // },
  // {
  //   value: "_740",
  //   label: "Silkeborg",
  // },
  // {
  //   value: "_657",
  //   label: "Herning",
  // },
  // {
  //   value: "_615",
  //   label: "Horsens",
  // },
  // {
  //   value: "_265",
  //   label: "Roskilde",
  // },
  // {
  //   value: "_370",
  //   label: "Næstved",
  // },
  // {
  //   value: "_540",
  //   label: "Sønderborg",
  // },
  // {
  //   value: "_157",
  //   label: "Gentofte",
  // },
  // {
  //   value: "_316",
  //   label: "Holbæk",
  // },
  // {
  //   value: "_860",
  //   label: "Hjørring",
  // },
  // {
  //   value: "_159",
  //   label: "Gladsaxe",
  // },
  // {
  //   value: "_376",
  //   label: "Guldborgsund",
  // },
  // {
  //   value: "_217",
  //   label: "Helsingør",
  // },
  // {
  //   value: "_813",
  //   label: "Frederikshavn",
  // },
  // {
  //   value: "_580",
  //   label: "Aabenraa",
  // },
  // {
  //   value: "_479",
  //   label: "Svendborg",
  // },
  // {
  //   value: "_746",
  //   label: "Skanderborg",
  // },
  // {
  //   value: "_760",
  //   label: "Ringkøbing-Skjern",
  // },
  // {
  //   value: "_259",
  //   label: "Køge",
  // },
  // {
  //   value: "_661",
  //   label: "Holstebro",
  // },
  // {
  //   value: "_510",
  //   label: "Haderslev",
  // },
  // {
  //   value: "_230",
  //   label: "Rudersdal",
  // },
  // {
  //   value: "_173",
  //   label: "Lyngby-Taarbæk",
  // },
  // {
  //   value: "_430",
  //   label: "Faaborg-Midtfyn",
  // },
  // {
  //   value: "_167",
  //   label: "Hvidovre",
  // },
  // {
  //   value: "_573",
  //   label: "Varde",
  // },
  // {
  //   value: "_607",
  //   label: "Fredericia",
  // },
  // {
  //   value: "_326",
  //   label: "Kalundborg",
  // },
  // {
  //   value: "_219",
  //   label: "Hillerød",
  // },
  // {
  //   value: "_169",
  //   label: "Høje-Taastrup",
  // },
  // {
  //   value: "_151",
  //   label: "Ballerup",
  // },
  // {
  //   value: "_253",
  //   label: "Greve",
  // },
  // {
  //   value: "_779",
  //   label: "Skive",
  // },
  // {
  //   value: "_710",
  //   label: "Favrskov",
  // },
  // {
  //   value: "_766",
  //   label: "Hedensted",
  // },
  // {
  //   value: "_390",
  //   label: "Vordingborg",
  // },
  // {
  //   value: "_360",
  //   label: "Lolland",
  // },
  // {
  //   value: "_787",
  //   label: "Thisted",
  // },
  // {
  //   value: "_250",
  //   label: "Frederikssund",
  // },
  // {
  //   value: "_575",
  //   label: "Vejen",
  // },
  // {
  //   value: "_846",
  //   label: "Mariagerfjord",
  // },
  // {
  //   value: "_240",
  //   label: "Egedal",
  // },
  // {
  //   value: "_706",
  //   label: "Syddjurs",
  // },
  // {
  //   value: "_420",
  //   label: "Assens",
  // },
  // {
  //   value: "_400",
  //   label: "Bornholm",
  // },
  // {
  //   value: "_185",
  //   label: "Tårnby",
  // },
  // {
  //   value: "_756",
  //   label: "Ikast-Brande",
  // },
  // {
  //   value: "_270",
  //   label: "Gribskov",
  // },
  // {
  //   value: "_210",
  //   label: "Fredensborg",
  // },
  {
    value: "_550",
    label: "Tønder",
    website: "https://www.toender.dk",
    max_lon: 9.03,
    max_lat: 55.03,
    min_lon: 8.74,
    min_lat: 54.87,
  },
  // {
  //   value: "_849",
  //   label: "Jammerbugt",
  // },
  // {
  //   value: "_190",
  //   label: "Furesø",
  // },
  // {
  //   value: "_770",
  //   label: "Norddjurs",
  // },
  // {
  //   value: "_410",
  //   label: "Middelfart",
  // },
  {
    value: "_820",
    label: "Vesthimmerland",
    website: "https://vesthimmerland.dk",
    feedback: "https://vesthimmerland.viewer.dkplan.niras.dk/plan/19#/18988",
    max_lon: 9.6,
    max_lat: 57.03,
    min_lon: 9.16,
    min_lat: 56.65,
  },
  // {
  //   value: "_175",
  //   label: "Rødovre",
  // },
  // {
  //   value: "_810",
  //   label: "Brønderslev",
  // },
  // {
  //   value: "_153",
  //   label: "Brøndby",
  // },
  // {
  //   value: "_329",
  //   label: "Ringsted",
  // },
  // {
  //   value: "_306",
  //   label: "Odsherred",
  // },
  // {
  //   value: "_450",
  //   label: "Nyborg",
  // },
  // {
  //   value: "_260",
  //   label: "Halsnæs",
  // },
  // {
  //   value: "_340",
  //   label: "Sorø",
  // },
  // {
  //   value: "_480",
  //   label: "Nordfyn",
  // },
  // {
  //   value: "_840",
  //   label: "Rebild",
  // },
  // {
  //   value: "_165",
  //   label: "Albertslund",
  // },
  // {
  //   value: "_350",
  //   label: "Lejre",
  // },
  // {
  //   value: "_163",
  //   label: "Herlev",
  // },
  // {
  //   value: "_530",
  //   label: "Billund",
  // },
  // {
  //   value: "_223",
  //   label: "Hørsholm",
  // },
  // {
  //   value: "_201",
  //   label: "Allerød",
  // },
  // {
  //   value: "_440",
  //   label: "Kerteminde",
  // },
  // {
  //   value: "_671",
  //   label: "Struer",
  // },
  // { value: "_336", label: "Stevns" },
  // { value: "_727", label: "Odder" },
  // { value: "_161", label: "Glostrup" },
  // { value: "_773", label: "Morsø" },
  // { value: "_665", label: "Lemvig" },
  // { value: "_269", label: "Solrød" },
  // { value: "_183", label: "Ishøj" },
  // { value: "_187", label: "Vallensbæk" },
  // { value: "_155", label: "Dragør" },
  // { value: "_482", label: "Langeland" },
  // { value: "_492", label: "Ærø" },
  // { value: "_741", label: "Samsø" },
  // { value: "_563", label: "Fanø" },
  // { value: "_825", label: "Læsø" },
];

const MapCodeToLabel = (code) => {
  let label = "";
  denmarkMunicipalityList.forEach((item) => {
    if (item.value === code) label = item.label;
  });
  return label;
};

const MapCodeToWebsite = (code) => {
  let website = "";
  denmarkMunicipalityList.forEach((item) => {
    if (item.value === code) website = item.website;
  });
  return website;
};

export {
  primaryBlue,
  blueLink,
  hoverBlue,
  darkGray,
  warningOrange,
  warningRed,
  lightGray,
  primaryGreen,
  currentDate,
  Divider,
  DividerThick,
  VerticalDivider,
  denmarkMunicipalityList,
  MapCodeToLabel,
  MapCodeToWebsite,
  getDeadlineDate,
  getDeadlineAsNumber,
  getProgressColor,
  isDeadlinePassed,
  calculatePriority,
};
