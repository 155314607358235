import { useState, useEffect, useContext } from "react";
import { UserProjectsContext } from "./Dashboard";
import { useTranslation } from "react-i18next";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "./../../graphql/mutations";
import "../../i18n";
import {
  SpaceBetween,
  TextContent,
  ColumnLayout,
  ExpandableSection,
} from "@cloudscape-design/components";
import { warningOrange, primaryBlue, isDeadlinePassed } from "../../shared";

export default function Productivity() {
  const { t } = useTranslation();
  const { userProjects, userDetails } = useContext(UserProjectsContext);
  const [ongoingProjects, setOngoingProjects] = useState(0);
  const [delayedProjects, setDelayedProjects] = useState(0);
  const [overdueTasks, setOverdueTasks] = useState(0);
  const [openTasks, setOpenTasks] = useState(0);

  const calculateOngoingProjects = () => {
    var count = 0;
    for (const project of userProjects) {
      if (!project?.projectClosed) count += 1; // project is ongoing if projectClosed is false
      setOngoingProjects(count);
    }
  };

  const calculateDelayedProjects = () => {
    var count = 0;
    for (const project of userProjects) {
      // project is delayed if either documents or tasks are not yet done and deadline has passed
      if (
        project?.tasks?.items.filter(
          (task) =>
            task.stage === project.stage &&
            task.deadline &&
            !task.done &&
            isDeadlinePassed(task.deadline)
        ).length ||
        project?.documents?.items.filter(
          (document) =>
            document.stage === project.stage &&
            document.deadline &&
            document.status !== "d_done" &&
            isDeadlinePassed(document.deadline)
        ).length
      )
        count += 1;
      setDelayedProjects(count);
    }
  };

  const calculateOverdueTasks = () => {
    var count = 0;
    for (const project of userProjects) {
      const overdueTasks = project?.tasks?.items.filter(
        (task) =>
          project?.stage === task?.stage &&
          task.deadline &&
          !task.done &&
          isDeadlinePassed(task.deadline)
      ).length;
      count += overdueTasks;
      setOverdueTasks(count);
    }
  };

  const calculateOpenTasks = () => {
    var count = 0;
    for (const project of userProjects) {
      const openTasks = project?.tasks?.items.filter(
        (task) =>
          project?.stage === task?.stage &&
          !task.done &&
          task?.assigneeId === userDetails?.id
      ).length;
      count += openTasks;
      setOpenTasks(count);
    }
  };

  const updateProductivityPreference = async (productivityExpanded) => {
    await API.graphql(
      graphqlOperation(mutations.updatePersona, {
        input: {
          id: userDetails.id,
          productivityExpanded,
        },
      })
    );
  };

  useEffect(() => {
    calculateOngoingProjects();
    calculateDelayedProjects();
    calculateOverdueTasks();
    calculateOpenTasks();
  }, []);

  return (
    <ExpandableSection
      fitHeight
      defaultExpanded={userDetails?.productivityExpanded}
      onChange={(e) => updateProductivityPreference(e.detail.expanded)}
      variant="container"
      headerText={
        <h3 style={{ marginTop: "-25px", paddingBottom: 0 }}>
          {t("Productivity")}
        </h3>
      }
    >
      <ColumnLayout borders="vertical" columns={4}>
        <TextContent>
          <SpaceBetween size="s">
            <h4>{t("Ongoing projects")}</h4>
            <h1 style={{ color: primaryBlue, fontSize: "60px" }}>
              {ongoingProjects || 0}
            </h1>
          </SpaceBetween>
        </TextContent>
        <TextContent>
          <SpaceBetween size="s">
            <h4>{t("Delayed projects")}</h4>
            <h1 style={{ color: warningOrange, fontSize: "60px" }}>
              {delayedProjects || 0}
            </h1>
          </SpaceBetween>
        </TextContent>
        <TextContent>
          <SpaceBetween size="s">
            <h4>{t("Open tasks")}</h4>
            <h1 style={{ color: primaryBlue, fontSize: "60px" }}>
              {openTasks | 0}
            </h1>
          </SpaceBetween>
        </TextContent>
        <TextContent>
          <SpaceBetween size="s">
            <h4>{t("Overdue tasks")}</h4>
            <h1 style={{ color: warningOrange, fontSize: "60px" }}>
              {overdueTasks || 0}
            </h1>
          </SpaceBetween>
        </TextContent>
      </ColumnLayout>
    </ExpandableSection>
  );
}
