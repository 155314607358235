import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";
import {
  AppLayout,
  ContentLayout,
  Container,
  SpaceBetween,
} from "@cloudscape-design/components";
import WindfarmBackground from "./assets/Windfarm-background.png";
import CommunityNavigationBar from "./components/community/CommunityNavigationBar";

export default function ComingSoon() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "EasyPermits®";
  }, []);

  return (
    <>
      <CommunityNavigationBar />
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout>
            <Container
              disableContentPaddings
              //fitHeight
            >
              <div
                style={{
                  textAlign: "center",
                  backgroundImage: `url(${WindfarmBackground})`,
                  backgroundSize: "100%",
                  backgroundPosition: "bottom",
                  backgroundRepeat: "no-repeat",
                  borderRadius: 7,
                }}
              >
                <SpaceBetween size="s">
                  <h1 style={{ fontSize: "6em", marginTop: "25%" }}>
                    {t("Coming Soon")}
                  </h1>
                  <div style={{ height: 400 }} />
                </SpaceBetween>
              </div>
            </Container>
          </ContentLayout>
        }
      />
    </>
  );
}
