import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TopNavigation } from "@cloudscape-design/components";
import Logo from "../../assets/easypermits-logo.svg";

export default function CommunityNavigationBar() {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("");
  const mapLanguageCode = {
    en: "English",
    da: "Danish (Dansk)",
    pl: "Polish (Polski)",
    fr: "French (Français)",
  };

  return (
    <TopNavigation
      identity={{
        href: "/",
        logo: {
          src: Logo,
          alt: "easypermits",
        },
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: mapLanguageCode[i18n.resolvedLanguage],
          items: [
            {
              id: "en",
              text: (
                <span style={{ fontWeight: selectedLang === "en" && "bold" }}>
                  English
                </span>
              ),
            },
            {
              id: "da",
              text: (
                <span style={{ fontWeight: selectedLang === "da" && "bold" }}>
                  Danish (Dansk)
                </span>
              ),
            },
            {
              id: "pl",
              text: (
                <span style={{ fontWeight: selectedLang === "pl" && "bold" }}>
                  Polish (Polski)
                </span>
              ),
            },
            {
              id: "fr",
              text: (
                <span style={{ fontWeight: selectedLang === "fr" && "bold" }}>
                  French (Français)
                </span>
              ),
            },
          ],
          onItemClick: (event) => {
            i18n.changeLanguage(event.detail.id);
            setSelectedLang(event.detail.id);
          },
        },
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu",
      }}
    />
  );
}
