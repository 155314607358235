import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { ProjectDetailsContext } from "./ProjectDashboard";
import {
  primaryBlue,
  primaryGreen,
  warningOrange,
  warningRed,
  Divider,
  isDeadlinePassed,
} from "../../shared";
import {
  Container,
  Header,
  SpaceBetween,
  Link,
} from "@cloudscape-design/components";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import LoopIcon from "@mui/icons-material/Loop";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// COLLAPSED DOCUMENTS VIEW
// Only show Documents of the current project stage
// For all documents in all stages, go to ExpandedDocuments.js

export default function Documents(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { projectDetails } = useContext(ProjectDetailsContext);
  const [documents, setDocuments] = useState([]);

  const listDocuments = async () => {
    setDocuments(
      projectDetails?.documents?.items
        .filter(
          (document) =>
            document?.stage === props.viewingStage &&
            document?.status !== "d_done"
        )
        .sort((a, b) => {
          if (a.deadline && b.deadline) return a.deadline - b.deadline;
          else return a.deadline ? -1 : 1;
        })
        .slice(0, 4) // only get first 4 documents to prevent displaying too much
    );
  };

  useEffect(() => {
    listDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails, props.viewingStage]);

  return (
    <Container
      fitHeight
      header={<Header variant="h2">{t("Documents")}</Header>}
      footer={
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Link href={"/project/" + id + "/documents"}>
            {t("View all documents")}
          </Link>
        </div>
      }
    >
      <div
        style={{
          paddingTop: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <b style={{ fontSize: "1.1em" }}>
          {projectDetails?.stage === "a_preplanning"
            ? t("Pre-planning")
            : projectDetails?.stage === "b_initial_consultation"
            ? t("Initial Public Consultation")
            : projectDetails?.stage === "c_planning"
            ? t("Planning Process")
            : projectDetails?.stage === "d_final_consultation"
            ? t("Final Consultation")
            : t("Final Approval")}
        </b>
        <span style={{ color: "gray" }}>
          {documents?.length !== 0 &&
            `${
              projectDetails?.documents?.items.filter(
                (document) =>
                  document?.stage === projectDetails?.stage &&
                  document?.status !== "d_done"
              )?.length
            } ${t("open documents")}`}
        </span>
      </div>

      <Divider />

      <div>
        {!documents ? (
          <p style={{ textAlign: "center" }}>{t("Loading")}...</p>
        ) : documents?.length === 0 ? (
          <div
            style={{
              color: "#434a54",
              textAlign: "center",
            }}
          >
            <b>{t("All done!")}</b>
            <br />
            {t("No open documents")}
          </div>
        ) : (
          documents.map((document) => (
            <div key={document?.id}>
              <SpaceBetween size="xs">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    paddingTop: 10,
                  }}
                >
                  <SpaceBetween>
                    <div style={{ display: "flex" }}>
                      {isDeadlinePassed(document?.deadline) ? (
                        <WarningIcon
                          sx={{ color: warningRed, marginRight: 1 }}
                        />
                      ) : (
                        (document?.status === "a_upload" && (
                          <CloudUploadIcon
                            sx={{ color: primaryBlue, marginRight: 1 }}
                          />
                        )) ||
                        (document?.status === "b_review" && (
                          <PendingIcon
                            sx={{ color: primaryBlue, marginRight: 1 }}
                          />
                        )) ||
                        (document?.status === "c_revise" && (
                          <LoopIcon
                            sx={{
                              color: warningOrange,
                              marginRight: 1,
                            }}
                          />
                        )) ||
                        (document?.status === "d_done" && (
                          <CheckCircleIcon
                            sx={{
                              color: primaryGreen,
                              marginRight: 1,
                            }}
                          />
                        ))
                      )}
                      <b>{t(document?.name)}</b>
                    </div>

                    <div style={{ marginLeft: 35, fontSize: "0.9em" }}>
                      <span
                        style={{
                          fontWeight:
                            isDeadlinePassed(document?.deadline) && "bold",
                          color:
                            isDeadlinePassed(document?.deadline) &&
                            warningOrange,
                        }}
                      >
                        {t("Deadline")}: {document?.deadline || "-"}
                        <br />
                      </span>
                      <span>
                        {t("Linked to")} <b>{document?.tasks.items.length}</b>{" "}
                        {document?.tasks.items.length > 1
                          ? t("tasks")
                          : t("task")}
                      </span>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween size="xs">
                    <div
                      style={{
                        float: "right",
                        textAlign: "center",
                        color: "white",
                        width: 120,
                        height: "auto",
                        verticalAlign: "middle",
                        display: "table-cell",
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRadius: 5,
                        padding: 3,
                        background:
                          (document?.status === "a_upload" && primaryBlue) ||
                          (document?.status === "b_review" && "#B083E1") ||
                          (document?.status === "c_revise" && warningOrange) ||
                          (document?.status === "d_done" && primaryGreen),
                      }}
                    >
                      {(document?.status === "a_upload" &&
                        t("Ready for upload")) ||
                        (document?.status === "b_review" &&
                          t("Sent for review")) ||
                        (document?.status === "c_revise" &&
                          t("Revision needed")) ||
                        (document?.status === "d_done" && t("Completed"))}
                    </div>
                  </SpaceBetween>
                </div>
                <hr style={{ borderTop: "#E9EBED" }} />
              </SpaceBetween>
            </div>
          ))
        )}
      </div>
    </Container>
  );
}
